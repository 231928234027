import Layout from 'app/admin/Layout'
import useLazyLoad from 'app/components/hooks/useLazyLoad';
import React from 'react'
import { InertiaLink } from '@inertiajs/inertia-react';
import { defaultButtons } from 'app/admin/ConfirmModal';
import WhiteContainer from 'app/admin/WhiteContainer';
import Button from 'app/components/Buttons/Button';
import InvertedButton from 'app/components/Buttons/InvertedButton';
import Icon from 'app/components/Icon';
import { MODALS, ModalsContext } from 'app/components/Layouts/Modals';
import Form from 'app/fragments/Forms/Form';
import Select from 'app/fragments/Forms/Select';
import route from 'ziggy-js';
import { AdminMeta } from '../menu';

interface Props {
    // reservations: Array<{id: number, first_name: string, last_name: string, amount_of_people: number, date: string, number: string, email: string, set_table: boolean, requirements: string}>
}

function Index(props: Props) {
    const { } = props
    const [reservations, button, meta, form, setReservations] = useLazyLoad<{ id: number, first_name: string, last_name: string, amount_of_people: number, date: string, number: string, email: string, set_table: boolean, requirements: string }>('reservations');
    return (
        <Layout>
            <div className=" pt-40px pb-130px">
                <div className="flex justify-between items-center gap-32px">
                    <div className="text-h32 font-semibold flex-shrink-0">Rezervace</div>
                    <div className='flex items-center gap-32px'>
                        <div className="flex items-center mt-18px justify-end">
                            <div className="mr-16px text-66666">Položek na stránku</div>
                            <Form form={form} className="w-70px">
                                <Select name="paginate"
                                    placeholder="10"
                                    options={[
                                        { text: "10", value: "10" },
                                        { text: "20", value: "20" },
                                        { text: "50", value: "50" },
                                        { text: "100", value: "100" },
                                    ]} />
                            </Form>
                        </div>
                        {/* <div className='flex gap-16px'>
                            <Button href={route('menu.create')} >
                                <Icon className="pr-16px" name="plus" />
                                Přidat položku
                            </Button>
                        </div> */}
                    </div>
                </div>

                <WhiteContainer className="mt-32px px-0 pb-0 pt-0">
                    <Form form={form} >
                        <div className="px-16px grid grid-cols-5 py-16px gap-x-12px bg-E1E0E0 text-black font-semibold">
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>ID</div>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-16px">Jméno</div>
                            </div>
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>Email</div>
                            </div>
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>Pozadavky</div>
                            </div>
                            <div className="flex justify-end">
                                <div className="flex items-center">
                                    <div className="mr-16px">Připravit stůl</div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {
                        reservations.map((p, i) =>
                            <div key={`menu-${p.id}`} className="border-t border-E1E0E0 grid grid-cols-5 px-16px py-11px text-16 gap-x-12px text-666666">
                                <div className="items-center flex ">{p.id}</div>
                                <div className="items-center flex">{p.first_name} {p.last_name}</div>
                                <div className="flex">{p.email}</div>
                                <div className="flex">{p.requirements}</div>
                                <div className='flex justify-end'>
                                    <div className={`w-20px h-20px rounded-full  ${p.set_table?"bg-green":"bg-redF"}`}></div>
                                </div>

                            </div>
                        )
                    }
                </WhiteContainer>
                <AdminMeta meta={meta} button={button} />
            </div>
        </Layout>
    )
}

export default Index
