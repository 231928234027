import ErrorGroup, { ErrorGroupContext } from 'app/fragments/Forms/ErrorGroup';
import { FormContext } from 'app/fragments/Forms/Form';
import TSlideContainer from 'app/fragments/TSlideContainer';
import Icon from 'components/Icon';
import { data } from 'jquery';
import React, { useContext, useEffect, useRef, useState } from 'react';

interface Props {
    children?: any
    title?: string | any
    className?: string
    classNameActive?: any
    textColor?: string
    defaultOpen?:boolean
    optional?:boolean
    insidePaddings?:boolean
    resetFields?: Record<string, any>
    resetTitle?: string
    onReset?: (data: any) => void
}
export default function AdminDropDown({ children, title, className, optional, insidePaddings = false, defaultOpen = false, textColor = "text-white", classNameActive = (a) => "", resetFields, onReset = (data: any) => {}, resetTitle }: Props) {

    const {setData, data, clearErrors, errors} = useContext(FormContext)

    const canDelete = resetFields && Object.keys(resetFields ?? {}).reduce((p, c) => ( p || (!!data[c] && JSON.stringify(data[c]) !== JSON.stringify(resetFields[c]) )) ?  true : false, false)

    const clearSectionErrors = (...fields) => {
        let all_fields = Object.keys(errors).filter( k => fields.filter( f => (k === f || k.includes(`${f}.`)) ).length > 0 )
        clearErrors(...all_fields)
    }

    return (
        <ErrorGroup>
            <ErrorGroupContext.Consumer >
                {
                    ({hasErrors, seterrors}) =>
                    <TSlideContainer
                            title={
                                <div className="flex justify-between w-full">
                                    <span>{title}</span>
                                    {
                                        canDelete &&
                                        <span onClick={() => { setData(d => { let n = { ...d,  ...(resetFields ?? {}) }; onReset(n); return n; } ); clearSectionErrors(...Object.keys(resetFields ?? {})); seterrors({}) }} className="text-red flex"> <span className="underline">{resetTitle} </span> <Icon name="bin" /></span>
                                    }
                                </div>
                            }
                            titleStyles={`text-16 font-bold ${insidePaddings && "px-24px pt-24px"}`}
                            containerStyles={`${insidePaddings? "pb-24px" : "p-24px" } mb-16px max-w-full border ${hasErrors ? "border-red" : " border-C4C4C4"} rounded transition-all duration-300 bg-white ${className} `}
                            defaultOpen={defaultOpen}
                            iconStyles={`w-12px`}
                            icon={optional ? (canDelete ? 'dropdown-arrow' : ((open) => open ? 'minus' : 'plus')) : undefined}
                    >
                        
                            {children}
                        
                    </TSlideContainer>
                }
            </ErrorGroupContext.Consumer>
        </ErrorGroup>
    );
}
