import { Method } from '@inertiajs/inertia'
import Button from 'app/components/Buttons/Button'
import usePageProps from 'app/components/hooks/usePageProps'
import React, { useEffect, useState } from 'react'

interface Props {
    title: string | any
    message: string | any
    buttons?: Array<typeof Button>
}


function BtnContainer({ children, seterrs }) {
    return (
        <div className="flex items-center justify-center" onClick={e => { e.preventDefault(); seterrs({}); }} >
            {children({ seterrs })}
        </div>
    );
}


function ConfirmModal(props: Props) {
    const { title, message, buttons } = props

    const { errors } = usePageProps<{ errors: Record<string, any> }>();

    const [errs, seterrs] = useState({})
    const [loaded, setloaded] = useState(false)

    useEffect(() => {
        if(loaded)
            seterrs(errors);
        else
            setloaded(true);
    }, [errors])




    return (
        <div className="bg-black bg-opacity-40  fixed top-0 left-0 w-full h-screen items-center justify-center flex z-99999 px-50px mob:px-24px mob:pb-0" >
            <div className="bg-white border border-brown-light p-24px max-w-sm shadow-card">
                <h3 className="font-bold text-h2 text-brown-light leading-none">{title}</h3>
                <div className="mb-24px mt-12px">{message}</div>
                <div className={`min-h-12px text-14 mb-8px text-C86B28 leading-tight ${errs?.['message'] ? "" : "opacity-0"}`}>{errs?.['message']}</div>
                <BtnContainer seterrs={seterrs}>
                    {buttons}
                </BtnContainer>
            </div>
        </div>
    )
}

export default ConfirmModal

export function defaultButtons(href: string, onSuccess: () => void, onCancel: () => void,) {
    return ({seterrs = (errs) => {}}) => [
        <Button
            href={href}
            method={Method.POST}
            preserveScroll
            preserveState
            only={['errors']}
            onSuccess={onSuccess}
            as="button"
            // defaultFocus={false}
            className="focus:outline-black"
            autoFocus
            tabIndex={0}
        >
            Ano
        </Button>,
        <Button
            defaultColor={false}
            href="#"
            className="bg-red hover:bg-opacity-80 text-white ml-12px focus:outline-black cursor-pointer"
            onClick={(e) => { e.preventDefault(); onCancel(); seterrs({}); }}
        >
            Zrušit
        </Button>
    ]
}
