import { InertiaLink } from "@inertiajs/inertia-react";
import Button from "app/components/Button";
import Img from "app/components/Image";
import route from "ziggy-js";
import { browserName } from "react-device-detect";
import { _ , t} from "app/components/Translator";


export default function Events() {


    return (
        <div className={"grid grid-cols-1 md:grid-cols-2 gap-x-24px " + (browserName == "Mobile Safari" ? " " : " ")}>
            <div className="flex w-full items-center justify-center">
                <div className="max-w-3xl md:p-40px">
                    <div className="text-24 md:text-h32 font-semibold pt-16px"><_>Eventy ve stylu Café New One</_></div>
                    <div className="text-brown-text text-16 my-20px">
                        <_>Hledáte dokonalé místo pro firemní večírek, narozeninovou oslavu, svatbu nebo módní přehlídku?</_>
                    </div>
                    <InertiaLink href={route('eventy')}>
                        <Button className=" hidden md:flex" value={t("Více o eventech")} />
                    </InertiaLink>
                </div>
            </div>
            <div className="relative w-full flex flex-col">
                <Img src="/assets/img/events.jpg" layout="fill" objectFit="cover" />
                <InertiaLink href={route('eventy')}>
                    <Button className=" flex w-full relative md:hidden mt-16px" value="Více o eventech" />
                </InertiaLink>

            </div>
        </div>
    )
}