import { InertiaLink } from "@inertiajs/inertia-react";
import { motion } from "framer-motion";
import { useState } from "react";
import route from "ziggy-js";
import Img from "./Image";
import { _ } from "./Translator";


export default function FooterButton() {

    const [hover, setHover] = useState(false)


    return (
        <InertiaLink 
        href={route('rezervace')}
        className="w-200px cursor-pointer h-200px flex items-center justify-center relative" onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}>
            <div className="text-64 font-aldo font-outline z-10 whitespace-nowrap"><_>Rezervovat</_></div>
            <motion.div
                animate={{
                    scale: hover ? 1.25 : 1
                }}
                transition={{
                    duration: 0.5
                }}
                className="absolute top-0 left-0 right-0 bottom-0 z-0"
            >
                <Img layout="fill" objectFit="contain" src="/assets/img/feat_bg.jpg" />
            </motion.div>
        </InertiaLink>
    )
}