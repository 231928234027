import usePageProps from "app/components/hooks/usePageProps";
import moment from "moment";

export let limit = (num, min = 1, max = 20) => {
  const MIN = min;
  const MAX = max;
  const parsed = parseInt(num)
  return Math.min(Math.max(parsed, MIN), MAX);
}


export let roundTo = (num: number, digits: number) => {
  let r = Math.pow(10, digits);
  return (Math.round(num * r) / r);
}


export const makeRatingArr = (r: number) => {

  let arr: Array<number> = [];

  for (let index = 0; index < 5; index++) {
    arr.push((r - 1) >= 0 ? 2 : (r - 1 > -1 ? 1 : 0))
    r--;
  }
  return arr;
}

export function getTrans(obj?: Array<string>) {

  const { locale } = usePageProps<any>()

  if (obj?.[1] && locale == "en") {
    return obj?.[1]
  }
  else if (obj?.[1] === null) {
    return null
  }
  else {
    return obj?.[0]
  }

}

export function now() {
  return moment()/* .tz(import.meta?.env?.VITE_TIMEZONE ?? 'UTC') */;
}