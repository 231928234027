import React, { InputHTMLAttributes } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> { }

function Submit(props: Props) {
    const { className, ...rest } = props

    return (
        <input className={`h-50px text-brown-light bg-transparent border border-brown-light hover:bg-brown-light hover:text-white transition-all  px-22px inline-flex items-center font-bold mt-12px cursor-pointer ${className}`}  {...rest} type="submit" />
    );
}

export default Submit
