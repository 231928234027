import axios from 'axios'

export const API_URL = process.env.MIX_IMG_HOST ?? "http://cafenewone.test";

export function POST(URL: string, data = {} as any, HOST = API_URL, withCredentials = true){
    axios.defaults.withCredentials = withCredentials;
    return axios.post(
        `${HOST}${URL}`, 
        data 
    ).then((res) => res.data)
    .catch(err => { throw err });
    
}

export function GET(URL: string, data = {} as any, HOST = API_URL){

    return axios.get(
        `${HOST}${URL}`, 
        data 
    ).then((res) => res.data)
    .catch(err => { throw err });
    
}