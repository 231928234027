import Icon from 'app/components/Icon'
import moment, { Moment } from 'moment'
import React, { InputHTMLAttributes, useContext, useEffect, useRef, useState } from 'react'
import { ErrorGroupContext } from './ErrorGroup'
import { FormContext } from './Form'
import TextField from 'app/components/Forms/TextField'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | any
    name: string
    placeholder?: string
    timePlaceholder?: string
}

enum VIEW {
    MONTH,
    YEAR,
    DECADE
}

function DateTimeInput(props: Props) {
    const { label, name, className, placeholder, timePlaceholder } = props

    const { data, setData, errors, clearErrors } = useContext(FormContext)

    const { seterrors } = useContext(ErrorGroupContext);
    useEffect(() => {
        seterrors(e => ({ ...e, [name]: errors[name] }))
    }, [errors[name]])

    const selected = data[name] ? moment(data[name], 'D. M. YYYY HH:mm') : null;

    const [date, setdate] = useState(selected ?? moment())


    const [view, setview] = useState(VIEW.MONTH);
    const [opened, setopen] = useState(false);

    const getMonthName = (i) => {
        date.month(i);
        return date.locale('cs-CZ').format('MMMM');
    }

    const changeMonthorYear = (i) => {

        setdate(y => {
            let d = moment(y);
            if (view == VIEW.MONTH)
                changeMonth(d.month() + i)
            else if (view == VIEW.YEAR)
                changeYear(d.year() + i)
            else
                changeYear(d.year() + (i * 10))
            return d
        })

    }

    const changeMonth = (i) => {
        setdate(y => {
            let d = moment(y);
            d.month(i)
            setData(name, d.format('D. M. YYYY HH:mm'))
            return d
        })
    }

    const changeYear = (i) => {
        setdate(y => {
            let d = moment(y);
            d.year(i)
            setData(name, d.format('D. M. YYYY HH:mm'))
            return d
        })
    }

    const changeDay = (i) => {
        setdate(y => {
            let d = moment(y);
            d.date(i)
            setData(name, d.format('D. M. YYYY HH:mm'))
            return d
        })
    }

    const changeHour = (i) => {
        setdate(y => {
            let d = moment(y);
            d.hours(i)
            setData(name, d.format('D. M. YYYY HH:mm'))
            return d
        })
    }

    const changeMinute = (i) => {
        setdate(y => {
            let d = moment(y);
            d.minutes(i)
            setData(name, d.format('D. M. YYYY HH:mm'))
            return d
        })
    }

    const removeDate = () => {
        setData(name, undefined);
    }

    const formatDate = (_date: Moment) => {
        return _date.format('D. M. YYYY');
    }

    const formatTime = (_date: Moment) => {
        return _date.format('HH:mm');
    }



    return (
        <div >
            <div>{label}</div>
            <div className="relative">
                <div className={`border ${errors[name] ? "border-E25F44" : "border-C4C4C4"} rounded bg-white  placeholder-C4C4C4 h-55px flex gap-6px box-border w-full items-center px-14px outline-none ${className}`}>
                    <input
                        className={` w-full outline-none placeholder-C4C4C4`}
                        type="text"
                        placeholder={placeholder}
                        value={selected ? formatDate(selected) : ''}
                        onClick={e => { setopen(o => !o) }}
                        readOnly
                    />
                    <input
                        className={`w-45px flex-shrink-0 outline-none placeholder-C4C4C4`}
                        type="text"
                        placeholder={timePlaceholder}
                        value={selected ? formatTime(selected) : ''}
                        onClick={e => { setopen(o => !o) }}
                        readOnly
                    />
                    <Icon onClick={e => { setopen(o => !o) }} name="calendar" className=" flex-shrink-0 cursor-pointer " />
                </div>
                {
                    opened &&
                    <Picker {...{ view, changeDay, getMonthName, changeMonthorYear, setview, selected, date, changeMonth, changeYear, setopen, removeDate, changeHour, changeMinute }} />
                }
            </div>
            <div className={`h-12px text-12 mt-4px text-red leading-none ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </div>
    )
}

export default DateTimeInput


function Picker({ view, changeDay, getMonthName, changeMonthorYear, setview, selected, date, changeMonth, changeYear, setopen, removeDate, changeHour, changeMinute }) {
    const suggestions = useRef<HTMLDivElement>(null)
    const [upOrDown, setupOrDown] = useState(false)
    const [leftOrRight, setleftOrRight] = useState(false)

    const isOutViewport = (element: HTMLDivElement | null) => {

        try {
            if (!element)
                return;

            var bounding = element.getBoundingClientRect();

            if (bounding.left < 0) {
                // Left side is out of viewoprt
            }

            if (bounding.bottom > (window?.innerHeight || document?.documentElement.clientHeight) && !upOrDown) {
                setupOrDown(true)
                console.log('down');
            }

            if (bounding.right > (window?.innerWidth || document?.documentElement.clientWidth)) {
                setleftOrRight(true);
            }

            if (bounding.top < 0) {
                // Top side is out of viewport
            }
        } catch (error) { }
    }

    const closePicker = () => setopen(false)


    useEffect(() => {
        isOutViewport(suggestions.current);
        try {
            window?.addEventListener('click', closePicker)
            $(() => {
                $(`.hour-${date.hours()}`)?.[0]?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                setTimeout(() => {
                    $(`.minute-${date.minutes()}`)?.[0]?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                }, 10);

            })
        } catch (error) { }

        return () => {
            try {
                window?.removeEventListener('click', closePicker)
            } catch (error) { }
        }
    }, [])

    function handleChangeOfTime(value: string) {
        console.log(value.split(':'))
        let time = value.split(':')

        if(time[0]?.length == 2 && time[1]?.length == 2){
            changeHour(time[0]);
            changeMinute(time[1]);
        }
        
    }

    return (
        <div ref={suggestions} onClick={e => e.stopPropagation()} className={`absolute bottom-0 transform translate-y-full flex gap-12px p-17px bg-white border border-EBE9E6 rounded-md z-50 -mb-18px w-max ${upOrDown ? `` : ""} ${leftOrRight && ` right-0 `}`}>
            <div>
                <Icon name="tab" className={`absolute -top-10px transition-all duration-300  z-10 ${leftOrRight ? ` right-20px ` : `left-20px`}`} />
                <Icon name="bin" onClick={() => removeDate()} className={`absolute top-6px right-6px transition-all duration-300 text-red hover:text-red-dark z-10 cursor-pointer`} />
                <div className="flex justify-between px-24px my-28px mt-12px">
                    <Icon onClick={(e) => { e.preventDefault(); changeMonthorYear(-1) }} className="transform rotate-180 cursor-pointer" name="arrow-right" />
                    {view == VIEW.MONTH && <div onClick={(e) => { e.preventDefault(); setview(VIEW.YEAR) }} className="mx-12px cursor-pointer">{getMonthName(date.month())} {date.year()}</div>}
                    {view == VIEW.YEAR && <div onClick={(e) => { e.preventDefault(); setview(VIEW.DECADE) }} className="mx-12px cursor-pointer">{date.year()}</div>}
                    {view == VIEW.DECADE && <div onClick={(e) => { e.preventDefault(); setview(VIEW.DECADE) }} className="mx-12px cursor-pointer">{date.year()} - {date.year() + 10}</div>}
                    <Icon onClick={(e) => { e.preventDefault(); changeMonthorYear(1) }} className="cursor-pointer" name="arrow-right" />
                </div>
                {
                    view == VIEW.MONTH &&
                    <div className="grid grid-cols-7 gap-x-5px gap-y-10px">
                        {
                            Array(date.daysInMonth()).fill(0).map((it, i) =>
                                <div onClick={() => { changeDay(i + 1); }} className={`bg-white border rounded-md border-EBE9E6 flex justify-center items-center w-45px h-45px cursor-pointer hover:bg-red hover:bg-opacity-50 hover:text-red hover:border-red-dark ${((date.month() == selected?.month() && date.year() == selected?.year()) && selected.date() == (i + 1)) && 'text-red border-red-dark bg-red bg-opacity-50'} `}>{i + 1}</div>
                            )
                        }
                    </div>
                }
                {
                    view == VIEW.YEAR &&
                    <div className="grid grid-cols-3 gap-x-5px gap-y-10px">
                        {
                            Array(12).fill(0).map((it, i) =>
                                <div onClick={() => { changeMonth(i); setview(VIEW.MONTH) }} className="bg-white border rounded-md border-EBE9E6 flex justify-center items-center px-5px h-45px cursor-pointer hover:bg-red hover:bg-opacity-50 hover:text-red hover:border-red-dark">{getMonthName(i)}</div>
                            )
                        }
                    </div>
                }
                {
                    view == VIEW.DECADE &&
                    <div className="grid grid-cols-5 gap-x-5px gap-y-10px">
                        {
                            Array(25).fill(0).map((it, i) =>
                                <div onClick={() => { changeYear(date.year() + i); setview(VIEW.YEAR) }} className="bg-white border rounded-md border-EBE9E6 flex justify-center items-center px-7px h-45px cursor-pointer hover:bg-red hover:bg-opacity-50 hover:text-red hover:border-red-dark">{date.year() + i}</div>
                            )
                        }
                    </div>
                }
                <div className='my-12px'>Čas*</div>
                <input
                    className={`border border-EBE9E6 rounded-default bg-white  placeholder-C4C4C4 h-55px flex box-border w-full items-center px-19px outline-none`}
                    type="text"
                    // value={data[name] ?? ""} 
                    onChange={e => { handleChangeOfTime(e.target.value) }}
                />
            </div>

            {/* <div className='w-110px pt-1px border-l border-EBE9E6 pl-4px flex flex-col'>
                <div className='my-28px mt-12px flex justify-center items-center font-semibold pl-8px'>
                    {date?.format('HH:mm')}
                </div>
                <div className='relative h-full'>
                    <div className='absolute w-full h-full flex gap-5px'>
                        <div className='overflow-auto tagscrollbar flex flex-col gap-8px flex-shrink-0 rtl pl-4px'>
                            {
                                Array(24).fill(0).map((it, i) =>
                                    <div onClick={() => { changeHour(i); }} className={`hour-${i} bg-white border rounded-md border-EBE9E6 flex justify-center items-center px-5px h-45px w-45px flex-shrink-0 cursor-pointer hover:bg-red hover:bg-opacity-50 hover:text-red hover:border-red-dark ${(date.hours() == i) && 'text-red border-red-dark bg-red bg-opacity-50'}`}>{moment(i, 'H').format('HH')}</div>
                                )
                            }
                        </div>
                        <div className='overflow-auto tagscrollbar flex flex-col gap-8px flex-shrink-0 pr-4px'>
                            {
                                Array(60).fill(0).map((it, i) =>
                                    <div onClick={() => { changeMinute(i); }} className={`minute-${i} bg-white border rounded-md border-EBE9E6 flex justify-center items-center px-5px h-45px w-45px flex-shrink-0 cursor-pointer hover:bg-red hover:bg-opacity-50 hover:text-red hover:border-red-dark ${(date.minutes() == i) && 'text-red border-red-dark bg-red bg-opacity-50'}`}>{moment(i, 'm').format('mm')}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
