import { InertiaLink, InertiaLinkProps } from '@inertiajs/inertia-react'
import React from 'react'

export interface LinkProps extends InertiaLinkProps {
    color?: string
}

function InvertedButton(props: LinkProps) {
    const {color = "white", className, ...rest} = props

    return (
        <InertiaLink preserveState className={`h-50px text-${color} border-${color} border-2  rounded-default justify-center px-22px text-center inline-flex items-center font-bold ${className}` } {...rest} />
    )
}

export default InvertedButton
