import { InertiaLink } from "@inertiajs/inertia-react";
import { useEffect, useState } from "react";
import Button from "./Button";
import { _, t } from "app/components/Translator";
import Img from "./Image";

function DispImage({ image }) {

    return (
        <a href={"https://www.instagram.com/cafenewone/?hl=cs"} target="_blank">
            <div className={" w-full relative pb-full load"} style={{ backgroundImage: "url(" + image.media_url + ")", backgroundSize: "cover" }}></div>
        </a>
    )
}

export default function InstagramGrid() {

    const [data, setData] = useState(null as any)
    const [rendered, setRendered] = useState(false)

    async function getFeed() {
        const response = await fetch("https://graph.instagram.com/me/media?fields=media_url,thumbnail_url&access_token=IGQWRQTzV1WU5yWmVLa2V4S1JJcEd6cHpSVDRocWF3bzJNMzA0X2lUQV80eFlPc3h6c2lzS0dqRFpVWWRJR19LUGphd3VCN2N4a3kxQS1qQWVKanRlWV8tQVZAxbVZAqb00xQTRlT01LTXEwR1RGWkxMb1VPNVBEQlUZD")
        const json = await response.json()
        const array = await json.data

        setData(array)
        setRendered(true)
        // console.log(array)
    }

    useEffect(() => {
        getFeed()
    }, [])

    return (
        <>
            <div className="w-full relative md:mb-48px">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-24px pb-24px">
                    <div className="grid grid-cols-2  gap-16px md:gap-24px overflow-hidden">
                        <div className="row-span-1 md:row-span-2 transform translate-y-0 md:translate-y-120px lg:translate-y-160px xl:translate-y-200px">
                            {data ? <DispImage image={data[0]} /> : <div className="w-full relative pb-full load"></div>}
                        </div>
                        <div>
                            {data ? <DispImage image={data[1]} /> : <div className="w-full relative pb-full load"></div>}
                        </div>
                        <div>
                            {data ? <DispImage image={data[2]} /> : <div className="w-full relative pb-full load"></div>}
                        </div>
                        <div className="md:hidden">
                            {data ? <DispImage image={data[3]} /> : <div className="w-full relative pb-full load"></div>}
                        </div>
                    </div>

                    <div className="hidden md:block w-full transform md:translate-y-60px lg:translate-y-80px xl:translate-y-100px xl:pb-24px">
                        {data ? <DispImage image={data[3]} /> : <div className="w-full relative pb-full load"></div>}
                    </div>
                </div>
                <a className="hidden md:flex" target="_blank" href="https://www.instagram.com/cafenewone/">
                    <Button value={t("Sledovat na Instagramu")} icon="instagram" />
                </a>
                <div className="hidden md:block absolute bottom-0 left-0 right-0 pointer-events-none">
                    <Img src="/assets/img/overlogo.svg" layout="fill" objectFit="contain" />
                </div>
            </div>
            <a className="md:hidden flex md:mt-24px" target="_blank" href="https://www.instagram.com/cafenewone/?hl=cs">
                <Button value="Sledovat na Instagramu" icon="instagram" />
            </a>
        </>

    )
}
