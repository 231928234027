import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import Layout from 'app/admin/Layout'
import Form from 'app/fragments/Forms/Form';
import React, { useContext } from 'react'
import Select from 'app/fragments/Forms/Select';
import WhiteContainer from 'app/admin/WhiteContainer';
import Button from 'app/components/Buttons/Button';
import InvertedButton from 'app/components/Buttons/InvertedButton';
import useLazyLoad from 'app/components/hooks/useLazyLoad';
import Icon from 'app/components/Icon';
import route from 'ziggy-js';
import { AdminMeta } from '../menu';
import { MODALS, ModalsContext } from 'app/components/Layouts/Modals';
import { defaultButtons } from 'app/admin/ConfirmModal';

interface Props { }

function Index(props: Props) {
    const { } = props



    const [dates, button, meta, form, setDates] = useLazyLoad<{ id, start, end, }>('dates');
    const { data } = form;
    let { open, close } = useContext(ModalsContext)

    const removeProduct = (e, id) => {
        e.preventDefault();

        open(MODALS.CONFIRM, false, {
            title: "Potvrdit smazání",
            message: "Opravdu chcete smazat položku?",
            buttons: defaultButtons(
                route('blocked_date.destroy', { blocked_date: id }),
                () => {
                    setDates(pr =>
                        pr.filter(f => f.id != id)
                    );
                    close();
                },
                close
            )
        })
    }

    return (
        <Layout>
            <div className=" pt-40px pb-130px">
                <div className="flex justify-between items-center gap-32px">
                    <div className="text-h32 font-semibold flex-shrink-0">Blokované data</div>
                    <div className='flex items-center gap-32px'>
                        <div className="flex items-center mt-18px justify-end">
                            <div className="mr-16px text-66666">Položek na stránku</div>
                            <Form form={form} className="w-70px">
                                <Select name="paginate"
                                    placeholder="10"
                                    options={[
                                        { text: "10", value: "10" },
                                        { text: "20", value: "20" },
                                        { text: "50", value: "50" },
                                        { text: "100", value: "100" },
                                    ]} />
                            </Form>
                        </div>
                        <div className='flex gap-16px'>
                            <Button onClick={(e)=>{e.preventDefault(); open(MODALS.ADD_DATE)}} href={route('menu.create')} >
                                <Icon className="pr-16px" name="plus" />
                                Přidat položku
                            </Button>
                        </div>
                    </div>
                </div>

                <WhiteContainer className="mt-32px px-0 pb-0 pt-0">
                    <Form form={form} >
                        <div className="px-16px grid grid-cols-6 py-16px gap-x-12px bg-E1E0E0 text-black font-semibold">
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>ID</div>
                            </div>
                            <div className="flex items-center col-span-4">
                                <div className="mr-16px">Termín</div>
                            </div>
                            <div className="flex justify-end">
                                <div className="flex items-center">
                                    <div className="mr-16px">Akce</div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {
                        dates.map((p, i) =>
                            <div key={`menu-${p.id}`} className="border-t border-E1E0E0 grid grid-cols-6 px-16px py-11px text-16 gap-x-12px text-666666">
                                <div className="items-center flex "><InertiaLink href={route("menu.edit", { menu: p.id })}>{p.id}</InertiaLink></div>
                                {/* <div className="items-center flex col-span-2"><InertiaLink href={route("menu.edit", { menu: p.id })}>{p.name}</InertiaLink></div> */}
                                <div className="flex col-span-4">{p.start} - {p.end}</div>
                                <div className="flex justify-end items-center">
                                    <div className="flex items-center">
                                        <div onClick={()=>{open(MODALS.UPDATE_DATE, false, {blocked_date: p})}}><Icon className="mr-15px text-black" name="edit" /></div>
                                        <button
                                            onClick={e => removeProduct(e, p.id)}
                                        >
                                            <Icon className="text-red" name="bin" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </WhiteContainer>
                <AdminMeta meta={meta} button={button} />
            </div>
        </Layout>
    )
}

export default Index
