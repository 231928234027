import ContentContainer from "app/components/ContentContainer";
import Header from "app/components/Header/Main";
import Img from "app/components/Image";
import InstagramGrid from "app/components/InstagramGrid";
import JanLibra from "app/components/JanLibra";
import Layout from "app/components/Layouts/Layout";
import MenuAndReserve from "app/components/MenuAndReserve";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, scroller } from 'react-scroll'
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { t, TranslationProps, _ } from "app/components/Translator";
import moment from "moment";
import { format } from "date-fns";
import usePageProps from "app/components/usePageProps";
import { PageProps } from "@inertiajs/inertia";
import { menu } from "app/utils/menu";
import { getTrans } from "app/utils/utils";


interface MenuProps {
    menus: any
}


export default function Menu(props: MenuProps) {

    const [dailyMenu, setDailyMenu] = useState(null as any)
    const [scrolled, setScrolled] = useState(0);
    const [dayMenu, inView] = useInView()

    const { menus } = props

    // useEffect(() => {
    //     console.log("inviws", inView)
    //     if ((inView == true)) {
    //         setTimeout(() => {
    //             if (inView == true) {
    //                 console.log("fetching menu...")
    //                 if (!dailyMenu) {
    //                     getMenu()
    //                 }
    //             }
    //         }, 1000)

    //     }

    // }, [inView])

    // async function getMenu() {

    //     axios.get("/api/menu"
    //     )
    //         .then((response) => {
    //             console.log(response.data)
    //             setDailyMenu(renderMenu(response.data))
    //         })
    // }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('section') as string | null

        if (myParam && (scrolled <= 1)) {
            // alert("scroll: "+myParam)
            scroller.scrollTo(myParam)
            setScrolled(scrolled + 1)
        }

    });



    return (
        <Layout onlyReserve dark>
            <MenuAndReserve onlyReserve dark>
                <Header wide relative dark />
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="block lg:flex items-start bg-brown-dark text-white">
                    <div className="z-50 md:z-auto flex flex-grow-0 flex-shrink-0 w-full lg:w-200px sticky bg-brown-dark top-0 md:top-75px lg:top-1/4 self-start pt-16px pr-16px pl-16px lg:p-25px lg:flex-col justify-start items-start text-16 text-brown-inverted lg:gap-y-24px gap-x-24px overflow-x-auto lg:overflow-x-hidden border-b border-brown-dark lg:border-none border-opacity-16">

                        <Link activeClass="border-b border-browm-light text-white " className=" pb-10px cursor-pointer transition duration-200 whitespace-nowrap" to="Denní menu" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Denní menu</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="Menu à la carte" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Menu á la carte</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="Snídaně à la carte" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Snídaně á la carte</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="Nápojový list" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Nápojový list</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="Vinný list" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Vinný list</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white" className="  pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="Cocktail menu" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Cocktail menu</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="Slovo šéfkuchaře" spy={true} smooth={true} offset={-90} duration={500}>
                            <_>Slovo šéfkuchaře</_>
                        </Link>


                    </div>

                    <div className="flex-grow-1 flex-shrink-1 w-full">
                        <ContentContainer p onlyReserve menuShift>
                            <Section
                                title="Obědové menu"
                                name="Denní menu"
                                subtitle="V ceně poledního menu je džbán 0,25l kohoutkové vody a naše focaccia."
                                time="Polední menu podáváme od 11:30 do 14:00"
                            >
                                <motion.div
                                    animate={{ maxHeight: menus ? 1999 : 250 }}
                                    transition={{ duration: 2 }}
                                    ref={dayMenu} className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-x-120px overflow-hidden min-h-200px">
                                    {Object.entries(menus)?.map(([key, day]: any, i) => (
                                        <motion.div
                                            animate={{ opacity: 1 }}
                                            initial={{ opacity: 0 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <h3 className="text-18 md:text-24 font-semibold my-20px">{(moment(new Date(), 'YYYY-MM-DD').locale('cs-CZ').format('dd D.M.') == moment(key, 'YYYY-MM-DD').locale('cs-CZ').format('dd D.M.') ? "Dnes" : moment(key, 'YYYY-MM-DD').locale('cs-CZ').format('dd D.M.'))}</h3>
                                            {day.map((meal) => (
                                                <Item name={meal.name} price={meal.price} />
                                            ))}

                                        </motion.div>
                                    ))}
                                    {!menus &&
                                        <div>
                                            <h3 className="text-18 md:text-24 font-semibold my-20px w-1/4 h-24px rounded load"></h3>
                                            <div className="load w-full h-24px my-20px" ></div>
                                            <div className="load w-full h-24px my-20px" ></div>
                                            <div className="load w-full h-24px my-20px" ></div>
                                            <div className="load w-full h-24px my-20px" ></div>
                                        </div>
                                    }

                                </motion.div>
                            </Section>
                            {menu?.map(menuSection => (
                                <Section
                                    title={getTrans(menuSection.category) as string}
                                    name={menuSection.category[0]}
                                >

                                    <div className=" md:columns-2 gap-x-120px">
                                        {menuSection?.groups.map(menuGroup => (
                                            <div className="break-inside-avoid">
                                                {menuGroup.name &&
                                                    (menuGroup.level === 2 ?
                                                        <h4 className="text-16 md:text-16 font-semibold my-20px">{getTrans(menuGroup.name)}</h4>
                                                        :
                                                        <h3 className="text-18 md:text-24 font-semibold my-20px">{getTrans(menuGroup.name)}</h3>
                                                    )
                                                }
                                                {menuGroup?.items?.map(menuItem => (
                                                    <Item name={getTrans(menuItem.name) as string} subtitle={getTrans(menuItem?.subtitle)} price={menuItem?.price?.[0]} />
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </Section>
                            ))}
                        </ContentContainer>
                    </div>
                </motion.div>
                <ContentContainer onlyReserve>
                    <JanLibra />
                </ContentContainer>
                <div className="lg:hidden">
                    <ContentContainer p onlyReserve>
                        <p className="block lg:hidden text-16 text-brown-inverted mb-16px">
                            <_>Začínal v hotelové kuchyni, kde projevil svůj talent a během dvou let se vypracoval z pozice Chef de Partie na Sous-chef. Tehdy stál u zrodu Café New One, později na dva roky Caféčko opustil, aby nasbíral zkušenosti a inspiraci v zahraničí, zejména na Islandu, v Irsku a na Maltě a vrátil se rovnou na pozici šéfkuchaře.</_>
                        </p>
                        <div className="block lg:hidden border-l border-brown-light pl-24px text-16 italic text-brown-inverted">
                            „<_>V Caféčku pro vás chceme vždy víc</_>“
                        </div>
                    </ContentContainer>
                </div>
                <ContentContainer p onlyReserve>
                    <InstagramGrid />
                </ContentContainer>
            </MenuAndReserve>
        </Layout>

    )
}

interface SectionProps {
    title?: string,
    name: string,
    subtitle?: string,
    children: any,
    time?: string
}

export function Section({ title, name, subtitle, children, time }: SectionProps) {
    const { locale } = usePageProps<PageProps>()
    console.log(locale)
    return (
        <div name={name} className="w-full">
            <div className="block md:flex justify-between items-center w-full pt-24px md:pt-80px">
                <h2 className="font-aldo w-full text-36 md:text-64 text-brown-light font-outline-brown leading-tight lowercase">
                    {(t(title) == "Obědové menu") ?
                        locale == "en" ?
                            (t(title))
                            :
                            <Img src="/assets/img/obedovemenu.svg" className="mb-16px w-160px md:w-325px " />
                        :
                        (t(title) == "Snídaně á la carte") ?
                            <Img src="/assets/img/snidane_outline.svg" className="mb-16px w-200px md:w-400px " />
                            :
                            (t(title))
                    }
                </h2>
                {time &&
                    <div className="flex md:flex items-center whitespace-nowrap">
                        <motion.div
                            transition={{ repeat: Infinity, duration: 1.6 }}
                            animate={{ opacity: 0 }}
                            initial={{ opacity: 1 }}
                            className="w-4px h-4px bg-white mr-8px rounded-full"></motion.div>
                        <div className="text-white font-semibold text-14">{t(time)}</div>
                    </div>
                }
            </div>
            {subtitle &&
                <div className="text-16 w-full mb-24px mt-16px md:mt-0">{t(subtitle)}</div>
            }


            <div className="w-full">
                {children}

            </div>
        </div>
    )
}

interface ItemProps {
    name?: string,
    subtitle?: string|null,
    price?: string
    kc?: boolean
}

export function Item({ name, subtitle, price, kc = true }: ItemProps) {

    return (
        <div className="w-full flex justify-between items-bottom relative mb-16px">

            <div className=" z-10 text-16  text-brown-inverted max-w-sm  ">
                <span className="bg-brown-dark font-semibold  pr-8px leading-tight">{t(name)}</span>
                {subtitle && <><br /><span className="bg-brown-dark font-normal  pr-8px">{t(subtitle)}</span></>}
            </div>

            {(kc && price) && <div className="bg-brown-dark z-10 pl-8px text-16 font-normal text-brown-inverted self-end whitespace-nowrap">{price} Kč</div>}
            {(kc && price) && <div className="z-0 absolute bottom-6px h-1px w-full bg-brown-inverted opacity-16"></div>}
        </div>
    )
}

function renderMenu(menu) {
    if (menu) {
        var div = document.createElement('div');
        div.innerHTML = menu.trim();
        var table = div.getElementsByTagName('tbody')
        let arrayOfElements = Array.from(table);
        const tds = div.querySelectorAll('td')
        const days = div.querySelectorAll('#home .col-xs-12 .col-xs-12')

        // console.log("days", days)

        let daysArray = Array.prototype.slice.call(days)
        // console.log("daysArray", daysArray)

        let returnedObj = [] as any

        daysArray.map((day) => {

            let string = day.childNodes[1].innerText
            // console.log("string", string)

            string = string.trim();

            let dayItem = { day: string, meals: [] as any }

            // console.log("dayItem", dayItem)
            const tables = Array.prototype.slice.call(day.querySelectorAll('table'))
            // console.log("tables", tables)
            const line = Array.prototype.slice.call(tables[1].querySelectorAll('tr'))
            // console.log("line", line)
            line.map((linepreItem) => {

                const property = Array.prototype.slice.call(linepreItem.querySelectorAll('td'))
                // console.log("property", property)

                let properties = { item: property[1].innerText, price: property[2].innerText }
                // console.log("properties", properties)

                if (!property[1].innerText.includes("Kohoutkové") && !property[1].innerText.includes("kohoutkové")) {
                    dayItem.meals.push(properties)
                }


            })
            // console.log("dayItem", dayItem)
            if (dayItem.meals.length > 0) {
                returnedObj.push(dayItem)
            }
        })
        // console.log("returnedObj", returnedObj)

        return returnedObj

        // let arr = Array.prototype.slice.call(tds)
        // let finalArr = [] as Array<any>

        // let finalObj = [] as any

        // arr.forEach((single, j) => {

        //     let text = single.innerText

        //     text = text.replace("&nbsp;", " ")

        //     finalArr.push(text)
        // })

        // arr = finalArr
        // console.log(arr)

        // for (let i = 0; i < (arr.length / 3) - 1; i++) {

        //     console.log("r")

        //     finalObj.push({
        //         item: arr[(i * 3) + 1],
        //         price: arr[(i * 3) + 2]
        //     })
        // }

        // // 15:32 end of work


        // console.log(finalObj)
        // console.log(div)
        // console.log(table)


        // // Change this to div.childNodes to support multiple top-level nodes
        // return finalObj
    }

}