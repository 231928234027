/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// require('./components/Example');

import { InertiaApp } from '@inertiajs/inertia-react'
import { ScreenContextProvider } from 'components/ScreenContext';
import React from 'react'
import { render } from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill';
import { ModalsProvider } from './components/Layouts/Modals';
import { InertiaProgress } from '@inertiajs/progress'
// kick off the polyfill!
smoothscroll.polyfill();
import createInertiaApp from '@inertiajs/inertia-react/src/createInertiaApp'
import { Inertia } from '@inertiajs/inertia';
import route from 'ziggy-js';
import moment from 'moment';

Inertia.on('before', (event) => {
  if (event.detail.visit.url.pathname != window.location.pathname) {
    document.documentElement.classList.remove('scroll-smooth');
    document.body.classList.remove('scroll-smooth');
  }
})
Inertia.on('finish', (event) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('section') as string | null
  if (!myParam) {
    window.scrollTo(0, 0)
  }

  document.body.classList.add('scroll-smooth');
  document.documentElement.classList.add('scroll-smooth');
})

const theme = createTheme({
  typography: {
    fontFamily: 'Myriad',
    fontSize: 16,
  },
  palette: {
    primary: {
      main: '#897A69',
      light: '#45403C',
      dark: '#666666'
    },
    text: {
      primary: '#666666',
      secondary: '#666666',
      disabled: 'rgba(137, 122, 105, 0.48)',
    },
    action: {
      active: 'rgba(137, 122, 105, 0.48)'
    },

  },
  spacing: 2
});


// InertiaProgress.init({
//   color: 'black',
//   showSpinner: false
// });

import { AriaAttributes, DOMAttributes } from "react";
import { createTheme, ThemeProvider } from '@material-ui/core';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    name?: string;
  }
};

createInertiaApp({
  resolve: name => {
    let page = require(`./pages/${name}`).default;
    // page.layout = page => <CartContextProvider children={page} />;
    return page;
  },
  setup({ el, App, props }) {
    render(

      <ThemeProvider theme={theme}>
        <ModalsProvider>
          <App {...props} />
        </ModalsProvider>
      </ThemeProvider>

      , el
    )
  },
})
