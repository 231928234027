import { InertiaLink } from "@inertiajs/inertia-react";
import OpeningWidget from "app/components/Header/OpeningWidget";
import Img from "app/components/Img";
import route from "ziggy-js";


export default function UnderContruction() {

    return (
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-brown-dark">
            <div className="flex w-full h-full justify-center items-center p-20px">
                <div className="flex flex-col items-center justify-center gap-y-20px">
                    <InertiaLink href={route("index")} className="flex">
                        <Img className="" src={true ? "/assets/img/cafe_inverted.svg" : "/assets/img/logo.svg"} />
                    </InertiaLink>
                    <div className="text-white font-semibold">
                        <div className="max-w-sm text-center">Na webu momentálně probíhá údržba, za chvíli jsme zpět!</div>
                    </div>
                </div>

            </div>
        </div>
    )
}