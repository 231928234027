

export default function ContentContainer({p, children, onlyReserve, menuShift}:{p?: boolean, children: any, onlyReserve?: boolean, menuShift?: boolean}) {


    return (
        <div className={"w-full flex justify-center px-0 " + (onlyReserve ? " md:pr-60px " : " md:px-60px ")}>
            <div className={"w-full "+ (p && " p-16px md:p-25px max-w-6xl")} >
            {children}
            </div>
        </div>
    )
}