import { usePage } from '@inertiajs/inertia-react';
import { ScreenContext } from 'components/ScreenContext';
import React, { useContext, useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import HeaderMobile from './HeaderMobile';
import Modals from './Modals';

interface Props {
    children?: any
    className?: string,
    title?: string
    onlyReserve?: boolean,
    dark?: boolean
}

export default function Layout({ children, className, title, onlyReserve, dark }: Props) {

    const { isMobile } = useContext(ScreenContext);

    return (
        <>
            <div id="layout" className="min-h-screen font-myriad">
                <div className={` z-10 relative    ${className}`}>
                    <main className=''>
                        {children}
                    </main>
                    <div id={"portal-view"} className="z-70"></div>
                    <div className="bg-transparent relative w-full h-0 md:h-450px" style={{ "pointerEvents": "none" }}></div>
                    <Footer onlyReserve={onlyReserve} dark={dark} />
                </div>
            </div>
        </>
    )
}
