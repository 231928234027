import { useForm } from '@inertiajs/inertia-react';
import { DatePicker } from '@material-ui/pickers';
import AdminDropDown from 'app/admin/AdminDropDown';
import EditWrapper from 'app/admin/EditWrapper';
import Layout from 'app/admin/Layout'
import Button from 'app/components/Buttons/Button';
import Icon from 'app/components/Icon';
import DateInput from 'app/fragments/Forms/DateInput';
import Form from 'app/fragments/Forms/Form';
import TextField from 'app/fragments/Forms/TextField';
import React from 'react'
import route from 'ziggy-js';

interface Props { 
    menu
}

function Edit(props: Props) {
    const { menu } = props
    const form = useForm({
        ...menu
    });
    const { data, post } = form;

    const submit = (e) => {
        e.preventDefault();
        post(route('menu.update', {menu: menu.id}), {
            onError: (err) => console.log(err),
            preserveState: true,
            only: ['errors']
        });
    }

    return (
        <Layout>
            <EditWrapper>
                <div className="1920min:px-130px pt-40px  pb-130px">
                    <Form form={form}>
                        <div className="flex justify-between items-center">
                            <div className="text-h32 font-semibold">Upravit položku</div>
                            <Button onClick={submit} href="#" >
                                <Icon className="" name="tick" />
                                <div className="ml-16px">Uložit</div>
                            </Button>
                        </div>
                        <AdminDropDown defaultOpen title="Základní informace" textColor="text-black" className="border border-EBE9E6 mt-32px">
                            <div className='flex gap-16px'>
                                <TextField name="name" placeholder="Polévka" required label="Název menu" />
                                <TextField name="price" placeholder="95.50" required label="Cena" />
                            </div>
                            <div className='w-full'>
                                <DateInput name={'date'} label="Datum"/>
                            </div>
                        </AdminDropDown>

                        {/* <Button href="#" onClick={submit} >
                            <Icon className="" name="tick" />
                            <div className="">Uložit</div>
                        </Button> */}
                    </Form>
                </div>
            </EditWrapper>
        </Layout>
    )
}

export default Edit
