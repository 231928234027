import { InertiaLink } from "@inertiajs/inertia-react";
import route from "ziggy-js";
import Img from "../Img";
import MenuButton from "./MenuButton";
import OpeningWidget from "./OpeningWidget";


export default function Header({ wide, relative, dark }: { wide?: boolean, relative?: boolean, dark?: boolean }) {

    return (
        <>
            <header className={"z-40  left-0 right-0 md:right-60px p-16px md:p-25px border-b  border-opacity-16 fixed flex justify-between " + (wide ? " left-0 mt-0 " : " md:left-60px mt-0 md:mt-0") + (dark ? " bg-brown-dark text-white border-brown-inverted" : " bg-white text-brown-dark border-brown-dark")}>
                <InertiaLink href={route("index")} className="flex">
                    <Img className="mr-16px" src={dark ? "/assets/img/cafe_inverted.svg" :"/assets/img/logo.svg"} />
                    <OpeningWidget dark={dark} />
                </InertiaLink>
                <MenuButton noMobileShift={wide ? true : false} dark={dark} />
            </header>
            {relative &&
                <div className="h-60px md:h-75px w-full"></div>
            }

        </>
    )
}