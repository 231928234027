import { Page, PageProps } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import React from 'react'
import usePageProps from './hooks/usePageProps';
interface Props {
    children: string
}
export interface TranslationProps extends PageProps {
    translations?: any
}
function Translator(props: Props) {
    const { children } = props
    const { translations } = usePageProps<TranslationProps>()
    function translate(key: string) {
        return translations?.[key] ?? key;
    }
    return (
        <>
            {translate(children)}
        </>
    )
}

export function t(what) {

    const { translations } = usePageProps<TranslationProps>()

    return translations?.[what] ?? what;


}
export default Translator
export const _ = Translator;
















