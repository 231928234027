import Button from "app/components/Button";
import ContentContainer from "app/components/ContentContainer";
import Header from "app/components/Header/Main";
import OpeningWidget from "app/components/Header/OpeningWidget";
import Img from "app/components/Image";
import Layout from "app/components/Layouts/Layout";
import MenuAndReserve from "app/components/MenuAndReserve";
import { motion } from "framer-motion";
import { stringify } from "postcss";
import { Children } from "react";
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Map from 'components/Map'
import JanLibra from "app/components/JanLibra";
import ServicesSlideshow from "app/components/Pages/Index/ServicesSlideshow";
import Events from "app/components/Pages/Index/Events";
import { InertiaLink } from "@inertiajs/inertia-react";
import route from "ziggy-js";
import InstagramGrid from "app/components/InstagramGrid";
import Counter from "app/components/Counter";
import { _, t } from "app/components/Translator";
import Kosvanec from "app/components/Kosvanec";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import { isMobile } from "react-device-detect";


export default function Eventy() {

    SwiperCore.use([Autoplay]);

    return (
        <Layout onlyReserve>
            <MenuAndReserve onlyReserve>
                <Header wide relative />
                <ContentContainer onlyReserve p>
                    <div className="relative w-full pb-100px">
                        <div className="py-24px md:py-80px grid grid-cols-1 md:grid-cols-2 gap-x-48px ">
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="text-24 md:text-h32 font-semibold text-black"><_>Váš event ve stylu Café New One</_></h1>
                                <p className="my-16px text-brown-text"><_>Hledáte dokonalé místo pro firemní večírek, módní přehlídku, konferenci, svatbu nebo narozeninovou oslavu?</_></p>
                                <p className="mb-24px text-black font-semibold"><_>Zavolejte nám na:</_> <a href="tel:+420734391471" className="text-brown-light font-semibold">+420 734 391 471</a></p>
                                <div className="grid grid-cols-2 w-full gap-24px">
                                    <FeatItem title={500} append="m2" subtitle={t("Celková plocha")} />
                                    <FeatItem title={250} append={t(" osob")} subtitle={t("Maximální kapacity")} />
                                    <FeatItem title={7} append={t(" toalet")} subtitle={t("Sociální zázemí")} />
                                    <div className="md:hidden relative w-full pb- overflow-hidden">
                                        <Img src="/assets/img/events.jpg" layout="fill" objectFit="cover" />
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block relative">
                                <Img src="/assets/img/events.jpg" layout="fill" objectFit="cover" />
                            </div>
                            <div className="absolute md:bottom-24px bottom-0 sm:top-387px md:top-auto w-full left-0 md:right-0 md:mx-auto flex items-start md:items-center justify-start md:justify-center">
                                <div className="w-2/3 md:w-1/2 sm:h-300px md:h-250px lg:h-300px relative">
                                    <Img src="/assets/img/contact_img.jpg" layout="fill" objectFit="cover" />
                                </div>

                            </div>
                        </div>
                    </div>
                </ContentContainer>
                <ContentContainer onlyReserve>
                    <div className="w-full relative mt-32px">
                        <div className="w-full h-300px md:h-600px lg:h-600px xl:h-815px  overflow-hidden  relative">
                            <Swiper
                                spaceBetween={isMobile ? 16 : 50}
                                slidesPerView={1.2}
                                autoplay={{ delay: 1 }}
                                speed={5000}
                                freeMode
                                loop
                                allowTouchMove={false}
                                className="w-full h-full"
                            >
                                <SwiperSlide>  <Img src="/assets/img/catering1-min.jpg" width="100%" layout="fill" objectFit="cover" /></SwiperSlide>
                                <SwiperSlide>  <Img src="/assets/img/catering2-min.jpg" width="100%" layout="fill" objectFit="cover" /></SwiperSlide>
                                <SwiperSlide>  <Img src="/assets/img/catering3-min.jpg" width="100%" layout="fill" objectFit="cover" /></SwiperSlide>
                                <SwiperSlide>  <Img src="/assets/img/catering4-min.jpg" width="100%" layout="fill" objectFit="cover" /></SwiperSlide>
                                <SwiperSlide>  <Img src="/assets/img/catering5-min.jpg" width="100%" layout="fill" objectFit="cover" /></SwiperSlide>
                            </Swiper>

                        </div>
                        <div className="relative md:absolute left-0 bottom-0 p-16px md:p-60px z-10 bg-white md:w-1/2">
                            <h2 className="text-24 md:text-h32 font-semibold mt-16px md:mt-0 mb-16px md:mb-24px"><_>Catering plný gastronomických zážitků</_></h2>
                            <p className="text-brown-text mb-24px"><_>V rámci eventu vám rádi zajistíme plný gastronomický servis. Prohlédněte si naši nabídku vybraných Itálií inspirovaných specialit a dezertů.</_></p>
                            <InertiaLink href={route('menu')}>
                                <Button value={t("Stálé menu")} />
                            </InertiaLink>

                        </div>
                    </div>
                </ContentContainer>
                <ContentContainer onlyReserve>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2">
                        <div className="w-full h-250px md:h-auto">
                            <Map />
                        </div>
                        <div className="p-16px my-16px md:my-0 md:p-48px grid grid-cols-2 md:grid-cols-1 gap-y-24px">
                            <FeatItem title={3} append=" h 20 m" subtitle={t("Praha (275 km)")} />
                            <FeatItem title={1} append=" h" subtitle={t("Brno (75 km)")} />
                            <FeatItem title={2} append=" h 30 m" subtitle={t("Vídeň (215 km)")} />
                        </div>
                    </div>
                </ContentContainer>
                <ContentContainer onlyReserve>
                    <Kosvanec />
                </ContentContainer>
                <div className="lg:hidden">
                    <ContentContainer p onlyReserve>
                        <p className="block lg:hidden text-16 text-brown-text mb-16px">
                            Manažerské zkušenosti v průběhu let získal v několika prvotřídních hotelových restauracích v Dublinu a Londýně, jeho srdce si však nakonec získala Olomouc, a když se naskytnula možnost stát se součástí příběhu Café New One, prý neváhal ani minutu. Z pozice F&B Managera dohlíží na to, aby se u nás všechno fungovalo jako na drátkách.
                        </p>
                        <div className="block lg:hidden border-l border-brown-light pl-24px text-16 italic text-brown-text">
                            „<_>Hlavní kouzlo všeho, co děláme, tkví v osobním přístupu. Chci, aby každý, kdo do Café New One zavítá, cítil, že přišel na místo, kde ho lidé dobře znají.</_>“
                        </div>
                    </ContentContainer>
                </div>
                <ContentContainer p onlyReserve>
                    <InstagramGrid />
                </ContentContainer>
            </MenuAndReserve>
        </Layout>

    )
}

interface Props {
    title: number | string
    subtitle: string
    append?: string
}

function FeatItem({ title, subtitle, append }: Props) {

    return (
        <div className="">
            <p className="font-outline-brown text-h32 md:text-64 leading-tight font-aldo flex"><Counter from={1} to={title} />{append && <span className="ml-6px">{append}</span>}</p>
            <p className="text-black font-semibold">{subtitle}</p>
        </div>
    )
}