import { useForm } from '@inertiajs/inertia-react';
import DateTimeInput from 'app/fragments/Forms/DateTimeInput';
import Form from 'app/fragments/Forms/Form';
import React, { useContext } from 'react'
import Button from 'app/components/Buttons/Button';
import Icon from 'app/components/Icon';
import route from 'ziggy-js';
import { ModalsContext } from 'app/components/Layouts/Modals';

interface Props { }

function AddDate(props: Props) {
    const { } = props
    const form = useForm({});
    const { data, post } = form;
    let { close } = useContext(ModalsContext)
    function submit(e) {
        e.preventDefault();
        post(route('blocked_date.store'), {
            onSuccess: () => { close() }
        })
    }
    return (
        <div className='fixed top-0 left-0 bg-black bg-opacity-30 flex items-center justify-center w-full h-screen'>
            <div className='w-1/2 bg-white p-40px text-black mb-1/3'>
                <div className='text-h32 font-semibold'>Blokovat termín</div>
                <div className='flex '>
                    <Form form={form} className='flex justify-between w-full'>
                        <DateTimeInput label="Vyberte termín od" name="start" />
                        <DateTimeInput label="Vyberte termín do" name="end" />

                    </Form>
                </div>
                <div className='flex justify-between'>
                    <Button
                        defaultColor={false}
                        href="#"
                        className="bg-red hover:bg-opacity-80 text-white focus:outline-black cursor-pointer"
                        onClick={(e) => { e.preventDefault(); close(); }}
                    >
                        Zrušit
                    </Button>
                    <Button onClick={submit} href="#" >
                        <Icon className="" name="tick" />
                        <div className="ml-16px">Přidat</div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AddDate
