import Icon from 'app/components/Icon'
import React, { InputHTMLAttributes, useContext, useEffect } from 'react'
import { ErrorGroupContext } from './ErrorGroup'
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | any
    name: string
    labelClassName?: string
    wraperClassName?: string
    noCheck?: boolean
    clearSelection?: boolean
    noPrefix?: boolean
}

function TextField(props: Props) {
    const { label, className, name, labelClassName, noCheck = false, wraperClassName, noPrefix = false, clearSelection = false, ...rest } = props

    const { data, setData, errors, clearErrors } = useContext(FormContext)
    const { seterrors } = useContext(ErrorGroupContext);
    useEffect(() => {
        seterrors(e => ({ ...e, [name]: errors[name] }))
    }, [errors[name]])

    // useEffect(() => {
    //     if (noPrefix) {
    //         if ((data[name] as string)?.startsWith('+421'))
    //             setData(name, (data[name] as string).replace('+421', ''));

    //         if ((data[name] as string)?.startsWith('0'))
    //             setData(d => {
    //                 let s = d[name];
    //                 while (d.startsWith('0'))
    //                     s = s.substring(1);
    //             })
    //     }

    // }, [data[name]])

    const transform = (value: string) => {
        if(!noPrefix)
            return value;

        if (value?.startsWith('+421'))
            value = value.replace('+421', '');

        while (value.startsWith('0'))
            value = value.substring(1);



        return value;
    }


    return (
        <label className={("w-full relative flex flex-col box-border ") + (wraperClassName)}>
            <div className={(" ") + labelClassName}>{label}</div>
            <input
                className={`border ${errors[name] ? "border-E25F44" : "border-C4C4C4"}  rounded bg-white  placeholder-C4C4C4 h-55px flex box-border w-full items-center px-19px outline-none ${className}`}
                type="text"
                {...rest}
                name={name}
                value={data[name] ?? ""}
                onChange={e => { setData(name, transform(e.target.value)); clearErrors(name) }}
            />
            {
                (data[name]?.length > 0 && !noCheck) &&
                <div className={(`bg-green absolute top-0 right-0 ${label ? "mt-40px" : "mt-20px"}  mr-13px rounded-full w-22px h-22px flex justify-center items-center text-white`)}>
                    <Icon name="tick" />
                </div>
            }
            {
                (data[name]?.length > 0 && clearSelection && noCheck) &&
                <div onClick={() => setData(name, '')} className={(`absolute cursor-pointer top-0 right-0 ${label ? "mt-40px" : "mt-20px"}  mr-13px rounded-full w-22px h-22px flex justify-center items-center`)}>
                    <Icon name="cross" />
                </div>
            }
            <div className={`h-12px text-12 mt-4px text-C86B28 leading-none ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default TextField
