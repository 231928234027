import { InertiaLink } from "@inertiajs/inertia-react"
import Button from "app/components/Button"
import Img from "app/components/Image"
import { _ } from "app/components/Translator"
import { motion, AnimatePresence } from "framer-motion"
import { useEffect } from "react"
import { useState } from "react"
import route from "ziggy-js"


export default function ServicesSlideshow() {

    const [active, setActive] = useState("Snídaně")
    const [animate, setAnimate] = useState(true)

    useEffect(() => {
        if (window.scrollY < 800) {
            setAnimate(true)
            let timer = setInterval(() => {
                switch (active) {
                    case "Snídaně": return setActive("Obchodní jednání")
                    case "Obchodní jednání": return setActive("Pracovní oběd")
                    case "Pracovní oběd": return setActive("Odpolední káva a dort")
                    case "Odpolední káva a dort": return setActive("Romantická večeře")
                    case "Romantická večeře": return setActive("Večírek")
                    case "Večírek": return setActive("Snídaně")
                }
                setAnimate(true)
            }, 6000)
            return () => {
                clearInterval(timer);

            };
        }
        else {
            setAnimate(false)
        }
    }, [active, window.scrollY])

    return (
        <>
            <div className="pt-16px md:p-0 w-full">
                <div className="w-full mb-16px md:mb-48px flex justify-between gap-x-24px overflow-x-scroll md:overflow-x-hidden border-b border-opacity-16 border-brown-light">
                    <div className="md:hidden"></div>
                    <Heading item="Snídaně" active={active} setActive={setActive} animate={animate} />
                    <Heading item="Obchodní jednání" active={active} setActive={setActive} animate={animate} />
                    <Heading item="Pracovní oběd" active={active} setActive={setActive} animate={animate} />
                    <Heading item="Odpolední káva a dort" active={active} setActive={setActive} animate={animate} />
                    <Heading item="Romantická večeře" active={active} setActive={setActive} animate={animate} />
                    <Heading item="Večírek" active={active} setActive={setActive} animate={animate} />
                    <div className="md:hidden">
                        <div className="w-8px"></div>
                    </div>
                </div>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-2 mb-16px px-16px md:p-0">
                <div className="w-full pb-full relative mb-16px md:mb-0">
                    <AnimatePresence>
                        {(active == "Večírek") &&
                            <motion.div
                                exit={{
                                    x: -500, opacity: 0, rotate: -12, scale: 1
                                }}
                                animate={{ x: 0, opacity: 1, rotate: 0, scale: 1, transition: { delay: 0.1 } }}
                                initial={{ opacity: 0, scale: 0.9 }}
                                transition={{ ease: "easeIn" }}
                                className="absolute top-0 left-0 right-0 bottom-0"
                            >
                                <Img src="/assets/img/events.jpg" objectFit="cover" layout="fill" />
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Romantická večeře") &&
                            <motion.div
                                exit={{
                                    x: -500, opacity: 0, rotate: -12, scale: 1
                                }}
                                animate={{ x: 0, opacity: 1, rotate: 0, scale: 1, transition: { delay: 0.1 } }}
                                initial={{ opacity: 0, scale: 0.9 }}
                                transition={{ ease: "easeIn" }}
                                className="absolute top-0 left-0 right-0 bottom-0"
                            >
                                <Img src="/assets/img/vecere.png" objectFit="cover" layout="fill" />
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Odpolední káva a dort") &&
                            <motion.div
                                exit={{
                                    x: -500, opacity: 0, rotate: -12, scale: 1
                                }}
                                animate={{ x: 0, opacity: 1, rotate: 0, scale: 1, transition: { delay: 0.1 } }}
                                initial={{ opacity: 0, scale: 0.9 }}
                                transition={{ ease: "easeIn" }}
                                className="absolute top-0 left-0 right-0 bottom-0"
                            >
                                <Img src="/assets/img/dort.png" objectFit="cover" layout="fill" />
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Pracovní oběd") &&
                            <motion.div
                                exit={{
                                    x: -500, opacity: 0, rotate: -12, scale: 1
                                }}
                                animate={{ x: 0, opacity: 1, rotate: 0, scale: 1, transition: { delay: 0.1 } }}
                                initial={{ opacity: 0, scale: 0.9 }}
                                transition={{ ease: "easeIn" }}
                                className="absolute top-0 left-0 right-0 bottom-0"
                            >
                                <Img src="/assets/img/obed.png" objectFit="cover" layout="fill" />
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Obchodní jednání") &&
                            <motion.div
                                exit={{
                                    x: -500, opacity: 0, rotate: -12, scale: 1
                                }}
                                animate={{ x: 0, opacity: 1, rotate: 0, scale: 1, transition: { delay: 0.1 } }}
                                initial={{ opacity: 0, scale: 0.9 }}
                                transition={{ ease: "easeIn" }}
                                className="absolute top-0 left-0 right-0 bottom-0"
                            >
                                <Img src="/assets/img/jednani.png" objectFit="cover" layout="fill" />
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Snídaně") &&
                            <motion.div
                                exit={{
                                    x: -500, opacity: 0, rotate: 20
                                }}
                                animate={{ x: 0, opacity: 1, rotate: 0, transition: { delay: 0.1 } }}
                                initial={{ opacity: 0 }}
                                transition={{ ease: "easeIn" }}
                                className="absolute top-0 left-0 right-0 bottom-0"
                            >
                                <Img src="/assets/img/vejcebenedikt.png" objectFit="cover" layout="fill" className="object-right" />
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
                <div className="w-full h-full relative">
                    <AnimatePresence>
                        {(active == "Snídaně") &&
                            <motion.div
                                animate={{ opacity: 1, transition: { delay: 0.4 } }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                className="w-full h-full md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:m-auto flex flex-col justify-center items-stretch md:p-48px">
                                <div className="text-black text-24 md:text-h32 font-semibold mb-24px"><_>Snídaně nebo Brunch</_></div>
                                <div className="text-brown-text mb-24px text-16"><_>Ať už obchodní jednání, jako odměnu po náročném týdnu nebo prostě jen tak — ráno začněte nejlépe u nás. Vkročte do nového dne pravou nohou, třeba s našimi fenomenálními Vejci Benedikt.</_></div>
                                {/* <div className="text-brown-text mb-24px text-16 italic">Snídaně podáváme v praocvní dny do 10:30, o víkendech do 11:30.</div> */}
                                <InertiaLink href={route("menu", {section: "snidane"})}>
                                    <Button value="Objevit snídaňové menu" />
                                </InertiaLink>

                            </motion.div>
                        }
                    </AnimatePresence>

                    <AnimatePresence>
                        {(active == "Obchodní jednání") &&
                            <motion.div
                                animate={{ opacity: 1, transition: { delay: 0.4 } }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                className="w-full h-full  md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:m-auto  flex flex-col justify-center items-stretch md:p-48px">
                                <div className="text-black text-24 md:text-h32 font-semibold mb-24px"><_>Obchodní jednání</_></div>
                                <div className="text-brown-text mb-24px text-16"><_>Potřebujete na své obchodní partnery udělat dojem? V blízkosti Café New One, v srdci Olomouce, snadno zaparkujete. Přátelsky vás přivítá naše pozorná obsluha a celý zážitek z jednání podtrhne výborná káva.</_></div>
                                {/* <div className="text-brown-text mb-24px text-16 italic">Snídaně podáváme v praocvní dny do 10:30, o víkendech do 11:30.</div> */}
                                <InertiaLink href={route('kontakt')}>
                                    <Button value="Kde nás najdete" />
                                </InertiaLink>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Pracovní oběd") &&
                            <motion.div
                                animate={{ opacity: 1, transition: { delay: 0.4 } }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                className="w-full h-full  md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:m-auto  flex flex-col justify-center items-stretch md:p-48px">
                                <div className="text-black text-24 md:text-h32 font-semibold mb-24px"><_>Oběd</_></div>
                                <div className="text-brown-text mb-24px text-16"><_>Kvalitní lokální suroviny pod rukama našeho šéfkuchaře Jana Libry se mění v ty nejsenzačnější lahůdky. Zastavte se během týdne do Café New One na denní menu a o víkendu na menu à la carte a objevte výtečnou moderní kuchyni inspirovanou italským venkovem a Francií.</_></div>
                                {/* <div className="text-brown-text mb-24px text-16 italic">Snídaně podáváme v praocvní dny do 10:30, o víkendech do 11:30.</div> */}
                                <InertiaLink href={route('menu')}>
                                    <Button value="Denní menu" />
                                </InertiaLink>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Odpolední káva a dort") &&
                            <motion.div
                                animate={{ opacity: 1, transition: { delay: 0.4 } }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                className="w-full h-full  md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:m-auto  flex flex-col justify-center items-stretch md:p-48px">
                                <div className="text-black text-24 md:text-h32 font-semibold mb-24px"><_>Káva a dezerty</_></div>
                                <div className="text-brown-text mb-24px text-16"><_>Ztrácíte odpoledne drive? Načerpejte novou energii na další výkony a oslaďte si den s dezerty z naší cukrárny spolu se šálkem kávy Tonino Lamborghini.</_></div>
                                {/* <div className="text-brown-text mb-24px text-16 italic">Snídaně podáváme v praocvní dny do 10:30, o víkendech do 11:30.</div> */}
                                <InertiaLink href={route("menu", {section: "napojovy-list"})}>
                                    <Button value="Objevit nabídku" />
                                </InertiaLink>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Romantická večeře") &&
                            <motion.div
                                animate={{ opacity: 1, transition: { delay: 0.4 } }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                className="w-full h-full  md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:m-auto  flex flex-col justify-center items-stretch md:p-48px">
                                <div className="text-black text-24 md:text-h32 font-semibold mb-24px"><_>Romantická večeře</_></div>
                                <div className="text-brown-text mb-24px text-16"><_>Dokonalá práce se světlem, jemná hudba a sklenka některého z našich vybraných vín se vpodvečer snoubí s čerstvou domácí italskou kuchyní. Udělejte si nezapomenutelný večer a vydejte se se svou drahou polovičkou na večeři k nám.</_></div>
                                {/* <div className="text-brown-text mb-24px text-16 italic">Snídaně podáváme v praocvní dny do 10:30, o víkendech do 11:30.</div> */}
                                <InertiaLink href={route("menu", {section: "a-la-carte"})}>
                                    <Button value="Objevit nabídku" />
                                </InertiaLink>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {(active == "Večírek") &&
                            <motion.div
                                animate={{ opacity: 1, transition: { delay: 0.4 } }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                className="w-full h-full  md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:m-auto  flex flex-col justify-center items-stretch md:p-48px">
                                <div className="text-black text-24 md:text-h32 font-semibold mb-24px"><_>Večírek</_></div>
                                <div className="text-brown-text mb-24px text-16"><_>Po setmění, více než kdy jindy, vynikají majestátné lustry inspirované ohňostrojem. Interiérem se vznáší atmosféra jako ve filmu Velký Gatsby. A little party never killed nobody — nezapomenutelný večer začíná v Café New One. Třeba sklenkou prosecca nebo jedním z našich unikátních cocktailů.</_></div>
                                {/* <div className="text-brown-text mb-24px text-16 italic">Snídaně podáváme v praocvní dny do 10:30, o víkendech do 11:30.</div> */}
                                <InertiaLink href={route("menu", {section: "cocktail-menu"})}>
                                    <Button value="Objevit nabídku" />
                                </InertiaLink>
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>


            </div>
        </>
    )
}

function Heading({ item, active, setActive, animate }) {

    return (
        <div onClick={() => setActive(item)} className={"h-36px md:h-48px cursor-pointer flex whitespace-nowrap justify-start relative text-16 " + ((active == item) ? " text-brown-light hover:text-opacity-80 font-semibold " : " text-brown-text hover:text-brown-light ")}>
            <_>{item}</_>
            {(active == item) &&
                <motion.div
                    animate={{
                        width: ((animate) ? "100%" : 0)
                    }}
                    initial={{ width: 0 }}
                    transition={{ duration: 6, ease: "linear" }}
                    className="w-full h-1px bg-brown-light absolute bottom-0 left-0"></motion.div>
            }
        </div>
    )
}