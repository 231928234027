import React, { useState, useEffect, useRef } from 'react';

export default function Svg({ src, className="", ...props}: any){
  const ImportedIconRef = useRef(null as any);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const { ReactComponent: namedImport } = await import(`@svgr/webpack!../../assets${src}.svg`);
        ImportedIconRef.current = namedImport;
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [src]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;

    return <ImportedIcon {...props} fill="currentColor" className={className}/>;
  }

  return null;
};