import React from 'react'
import { useEffect } from 'react'
import Img from './Img'

interface Props {
    src: string
    layout?: string
    objectFit?: "contain" | "cover"
    quality?: number
    alt?: string
    priority?: boolean
    className?: string
    height?: number | string
    width?: number | string
    sizes?: any,
    placeholder?: string
}

function Image(props: Props) {
    const {src, layout, objectFit, quality, priority, alt, placeholder, className, height = (layout == "fill" ? "100%" : `${props.height}px`), width = (layout == "fill" ? "100%" : `${props.width}px`)} = props

    return (
        <Img className={className} src={src} alt={"image_"+alt} style={{objectFit: objectFit, height: height, width: width}}  />
    )
}

export default Image
