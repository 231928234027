import React, { FormHTMLAttributes, useEffect, useState } from 'react'
import { InertiaFormProps } from '@inertiajs/inertia-react'
import input from 'postcss/lib/input';
import IconText from '../IconText';
import Icon from 'app/components/Icon';

interface Props extends FormHTMLAttributes<HTMLFormElement> {
    form: InertiaFormProps<any>
}

export const FormContext = React.createContext<InertiaFormProps<Record<string, any>>>({} as InertiaFormProps);


function ErrorMessage({ form }) {

    const [showed, setshowed] = useState(false)

    useEffect(() => {
        setshowed((form.errors && Object.values(form.errors)?.length > 0));
    }, [form.errors])

    return (
        showed ?
        <div className=" relative">
            <div className="bg-red flex justify-between items-center text-white text-16 p-16px flex-grow fixed top-85px w-496px mob:w-full mob:top-55px z-99999 rounded mob:rounded-none leading-6">
                Prosím, opravte chyby ve formuláři.
                <Icon className="flex-shrink-0 cursor-pointer" name="cross" onClick={() => setshowed(false)} />
            </div>
        </div>
        : null
    );
}


export default function Form(props: Props) {
    const { children, form, ...rest } = props
    // console.log(form);
    return (
        <form {...rest}>
            <ErrorMessage form={form}></ErrorMessage>
            <FormContext.Provider value={{ ...form }}>
                {children}
            </FormContext.Provider>
        </form>
    )
}


