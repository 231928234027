
import Modals from 'app/components/Layouts/Modals'
import React, { HTMLProps, useState } from 'react'
import SideMenu from './SideMenu'
import { InertiaProgress } from '@inertiajs/progress'
import { InertiaLink } from '@inertiajs/inertia-react'
import route from 'ziggy-js'
import Icon from 'app/components/Icon'
import usePageProps from 'app/components/hooks/usePageProps'
import Img from 'app/components/Img'



interface Props extends HTMLProps<HTMLDivElement> {
    onLogin?: boolean
    restricted?: boolean
}

function Layout(props: Props) {
    const { children, onLogin = false, restricted = true } = props


    const { user } = usePageProps<{ user: { name: string, email: string, roles: Array<any> } }>();

    let [logoutOpen, setLogoutOpen] = useState(false)

    return (
        <div className="w-full overflow-hidden font-myriad">
            <div className="flex w-full">
                <SideMenu />
                <div className="w-full min-h-screen">
                    <div className="bg-white h-80px items-center flex flex-row px-24px w-full border-b border-E1E0E0">
                        <div className="flex justify-between w-full items-center">
                            <div className='font-bold text-36  text-brown-light flex items-center'>
                                <InertiaLink href={route('admin.index')} className='h-full flex items-center justify-center '>
                                    <Img src='/assets/img/logo.svg' className='h-full w-full object-contain' />
                                </InertiaLink>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-16px">
                                    <div className="font-semibold text-14">{user?.name}</div>
                                    {/* <div className="font-normal text-12">{user?.roles?.[0]?.name}</div> */}
                                </div>
                                <div onClick={() => { setLogoutOpen((p) => !p) }} className="relative mr-20px h-full flex items-center justify-center cursor-pointer">
                                    <Icon className={`transition-all transform ${logoutOpen && 'rotate-180'} w-12px`} name="dropdown-arrow" />
                                    {
                                        logoutOpen &&
                                        <div className="absolute bottom-0 right-0 transform translate-y-full -mr-30px -mb-10px z-99999 ">
                                            <div className="absolute top-0 right-0 mr-30px -mt-5px  w-12px h-12px bg-red transform rotate-45"></div>
                                            <InertiaLink className="bg-red hover:text-opacity-80 text-white min-w-max p-16px" href="/admin/logout" as="button" method="POST">
                                                Odhlásit se
                                            </InertiaLink>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={("bg-bg pl-23px pr-20px pt-26px flex justify-center overflow-hidden w-full h-full")}>
                        <div className={`text-16 w-full ${restricted ? "max-w-6xl" : " "}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <Modals />
        </div>
    )
}

export default Layout
