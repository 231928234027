import { useState, createContext, useEffect } from "react";

export const ScreenContext = createContext({ isMobile: false, checkMobile: () => { }, screenWidth: 0 });

var rtime = new Date();
var timeout = false;
var delta = 200;

export function ScreenContextProvider(props) {

    let [isMobile, setMobile] = useState(false);
    let [width, setWidth] = useState(0);

    let checkMobile = () => {
        //  console.log(window.screen.width, window.screen.availWidth, window.innerWidth)
        let isMob = window.screen.width < 1150;
        setWidth(window.innerWidth);
        setMobile(isMob);
    }

    let resizeend = () => {
        // @ts-ignore
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            checkMobile();
        }
    }

    useEffect(() => {
        checkMobile();
        window.addEventListener("resize", () => {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeend, delta);
            }
        })
    }, [])

    // useEffect(()=>{
    //     checkMobile();
    // }, [window.screen.width])

    return (
        <ScreenContext.Provider value={{ isMobile: isMobile, checkMobile: checkMobile, screenWidth: width }} >
            {props.children}
        </ScreenContext.Provider>
    )
}