import { InertiaLink } from '@inertiajs/inertia-react'
import Icon from 'components/Icon'
import React from 'react'
import route from 'ziggy-js'
import FooterButton from '../FooterButton'
import Img from '../Image'
import { _ } from '../Translator'

interface Props {
    onlyReserve?: boolean
    dark?: boolean
}

function Footer(props: Props) {
    const { onlyReserve, dark } = props

    let now = new Date().getFullYear()

    return (
        <footer className={"z-0 w-full pt-40px h-600px md:h-450px md:fixed bottom-0 text-white px-0 flex justify-center " + (dark ? " bg-brown-light " : " bg-brown-dark ") + (onlyReserve ? " md:pr-60px " : " md:px-60px ")}>
            <div className="max-w-6xl p-16px md:p-25px w-full grid grid-cols-1 md:grid-cols-2">
                <div className="flex flex-col justify-start items-start">
                    <div className="flex justify-between w-full">
                        <Img src="/assets/img/logo_w.svg" width="150px" />
                        <div className="md:hidden flex gap-x-16px">
                            <a target="_blank" href="https://www.instagram.com/cafenewone/?hl=cs"><Icon name="instagram" /></a>
                            <Icon name="facebook" />
                        </div>
                    </div>

                    <div className="text-16 text-white text-opacity-50 my-20px">
                        <_>Neustále překvapovat, vždy nadchnout</_>
                    </div>
                    <div className="grid grid-cols-2 w-full gap-16px text-16 cursor-pointer">
                        <InertiaLink href={route('menu')} className=" hover:opacity-75"><_>Jídelní lístek</_></InertiaLink>
                        {/* <InertiaLink href={route('pribeh')} className=" hover:opacity-75"><_>Příběh</_></InertiaLink> */}
                        {/* <InertiaLink href={route('menu')} className=" hover:opacity-75"><_>Menu</_></InertiaLink> */}
                        <InertiaLink href={route('kontakt')} className=" hover:opacity-75"><_>Kontakt</_></InertiaLink>
                        <InertiaLink href={route('eventy')} className=" hover:opacity-75"><_>Eventy</_></InertiaLink>
                        <InertiaLink href={route('rezervace')} className=" hover:opacity-75"><_>Rezervace</_></InertiaLink>
                    </div>
                </div>
                <div className="relative flex items-center justify-center">
                    <div className="hidden absolute right-0 top-0 md:flex gap-x-16px">
                        <a target="_blank" href="https://www.instagram.com/cafenewone/" className=" hover:opacity-75"><Icon name="instagram" /></a>
                        <a target="_blank" href="https://www.facebook.com/cafenewone/" className=" hover:opacity-75"><Icon name="facebook" /></a>
                        {/* <a target="_blank" href="https://www.instagram.com/cafenewone/?hl=cs" className=" hover:opacity-75"><Icon name="twitter" /></a> */}
                    </div>
                    <FooterButton />
                </div>
                <div className="md:col-span-2 block md:flex justify-between w-full text-white text-opacity-50">
                    <div className=" md:py-40px">
                    © 2014 - {now} Café New One, BALASHOUSE s. r. o., tř. Svobody 596/21, 779 00 Olomouc, IČ: 02663163
                    </div>
                    <div className=" md:py-40px">
                        <a href="/assets/cafenewone_GDPR.pdf" target="_blank" className="hover:opacity-75 text-white"><_>Zpracování osobních údajů</_></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

interface FLProps {
    href: string
    text: string | any
}

function FooterLink({ href, text }: FLProps) {
    return <InertiaLink href={href} className="mt-12px">{text}</InertiaLink>
}

