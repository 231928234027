import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Icon from "./Icon";
import _ from "components/Translator"


export default function Button({ value, main, className, forceFullWidth, type, onClick, icon, loading }: { value: string, main?: boolean, className?: string, forceFullWidth?: boolean, type?: "button" | "submit" | "reset" | undefined, onClick?: any, icon?: string, loading?: boolean }) {

    const [hover, setHover] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [bg, setBg] = useState(" bg-brown-light ")


    useEffect(() => {
        switch (value) {
            case "Odesílání": return setBg(" bg-brown-dark ")
            case "Odesláno": return setBg(" bg-green ")
            case "Chyba": return setBg(" bg-red ")
        }
    }, [value])

    return (
        <button
            disabled={loading ? true : false}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onPointerDown={() => setClicked(true)}
            onPointerUp={() => setClicked(false)}
            type={type ? type : undefined} className={(main ? (" text-white " + bg) : ("  border border-brown-light " + (hover ? " text-white z-10 " : " text-brown-light "))) + " px-16px cursor-pointer text-16 relative  whitespace-nowrap  hover:bg-opacity-90 transition duration-200 h-48px flex gap-x-8px items-center justify-center " + (className ? (" " + className) : " ") + (forceFullWidth ? " w-full " : " md:w-min w-full ")} onClick={() => onClick ? onClick : {}}>
            {!main &&
                <motion.div
                    animate={{ width: (hover ? "100%" : 0), backgroundColor: clicked ? "#45403C" : "#897A69"  }}
                    transition={{ ease: "easeInOut" }}
                    initial={false}
                    className={main ? " hidden " : " z-0 absolute top-0 left-0 bottom-0 right-0 h-full pointer-events-none "}></motion.div>
            }

            {(icon) &&
                <Icon name={icon} className={"flex-shrink-0 " + (hover ? " text-white z-10 " : "  ")} />
            }
            {loading &&
                <img src={"/assets/icons/loading.svg"} className={"w-24px h-24px flex-shrink-0 " + (hover ? " text-white z-10 w-20px h-20px " : " ")} />
            }

            <span className="z-10">
                <_>{value}</_>
            </span>


        </button>
    )
}