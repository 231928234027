import Icon from 'components/Icon';
import React, { Children, HTMLProps, useContext, useEffect, useRef, useState } from 'react'

interface Props extends HTMLProps<HTMLDivElement> {
    title?: string | any
    containerStyles?: string
    titleStyles?: string
    contentStyles?: string
    defaultOpen?: boolean
    iconStyles?: string
    icon?: string | ((open: boolean) => string)

}

const TSlideContext = React.createContext({update: () => {}});

function TSlideContainer(props: Props) {
    const {title, children, containerStyles, titleStyles, contentStyles, iconStyles, icon = "dropdown-arrow", defaultOpen = false, ...rest } = props
    const [open, setopen] = useState(defaultOpen)   
    const ref = useRef<HTMLDivElement>(null);
    const height = useRef("0px");
    const {update} = useContext(TSlideContext)

    const collapseSection = () => {
        let element = ref.current;
        if (element) {
          
            var sectionHeight = element.scrollHeight;        
            var elementTransition = element.style.transition;
            element.style.transition = '';
  
            requestAnimationFrame(function () {
                if (element) {
                    height.current = sectionHeight + 'px';
                    element.style.height = height.current;
                    element.style.transition = elementTransition;
                }
                requestAnimationFrame(function () {
                    if (element) {
                        height.current = 0 + 'px';
                        element.style.height = height.current;
                    }
                });
            });

            setopen(false);
            update();
        }
    };

    const expandSection = () => {
        let element = ref.current;
        if (element) {
            var sectionHeight = element.scrollHeight;
            height.current = sectionHeight + 'px';
            element.style.height = height.current;
            setopen(true);
            update();
        }
    };

    const toggle = () => {

        addOverflow()
        
        if(open)
            collapseSection()
        else
            expandSection()


    }

    const updatefn = () => {
        let element = ref.current;
        if (element) {
            height.current = 'auto';
            element.style.height = height.current;
            setopen(true);
            update();
        }
    }

    const removeTransition = (e) => {
        let element = ref.current;
        if (element && open) {
            // remove this event listener so it only gets triggered once
            // element.removeEventListener('transitionend', removeTransition);

            // remove "height" from the element's inline styles, so it can return to its initial value
            element.style.height = "initial";
            element.style.overflow = "initial";
        }
    };

    const addOverflow = () => {
        let element = ref.current;
        if (element) {     
            element.style.overflow = "hidden";
        }
    }

    return (
        <div className={`relative ${containerStyles} `}>
            <div onClick={toggle} className={`flex justify-between items-center cursor-pointer ${titleStyles} `}>
                {title}
                {(Children.count(children) > 0) && <span className={`flex-shrink ml-12px transform transition-transform duration-300 ${open ? "rotate-180" : ""}`}><Icon className={(iconStyles)} name={ typeof icon === 'string' ? icon : icon(open)} /></span>}
            </div>
            <div ref={ref} onTransitionEnd={removeTransition} className={`${!defaultOpen && "overflow-hidden"} collapsable w-full  ${contentStyles} `} style={defaultOpen ? {} : {height: height.current}} >
                <TSlideContext.Provider value={{update: updatefn }}>
                    {children}
                </TSlideContext.Provider>
            </div>
        </div>
    )
}

export default TSlideContainer
