import { InertiaLink } from "@inertiajs/inertia-react";
import { Checkbox, FormControlLabel, NativeSelect, Slider, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "app/components/Button";
import Icon from "app/components/Icon";
import Img from "app/components/Image";
import { motion } from "framer-motion";
import { useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import axios from "axios";
import { _, t } from "app/components/Translator";
import route from "ziggy-js";
import moment from "moment";
import { Inertia } from "@inertiajs/inertia";

interface RezervaceProps {
    blocked_dates: Array<{ id: number, start: string, end: string, start_front: string, end_front: string }>
}

export default function Rezervace(props: RezervaceProps) {
    const { blocked_dates } = props
    const [time, setTime] = useState(null as any)
    const [sentState, setSentState] = useState("Rezervovat" as any)
    const [manualPersons, setManualPersons] = useState(false)
    const [timeError, setTimeError] = useState(false)
    const [dateError, setDateError] = useState(false)

    const forbiddenDates = [
        // 24. prosinec 2023
        "2023-12-24",
        // 25. prosinec 2023
        "2023-12-25",
        // 31. prosinec 2023
        "2023-12-31",
        // 1. ledna 2024
        "2024-01-01",

        // 8. prosinec 2023
        "2023-12-08",
        // 15. prosinec 2023
        "2023-12-15"]

    const [formData, setFormData] = useState({
        f_name: "",
        l_name: "",
        people: 1 as number | string,
        date: new Date(),
        time: "12:00",
        phone: "",
        mail: "",
        extra_place_setting: false,
        message: "",
        gdpr: false,
        newsletter: false
    })

    function updateForm(what, toWhat) {
        setFormData({
            ...formData,
            [what]: toWhat
        })
    }



    async function fakesend() {

        const now = new Date()
        let dateToTest = new Date(formData.date)
        if (formData.time[1] == ":") {
            dateToTest.setHours(Number(formData.time[0]))
            dateToTest.setMinutes(Number(formData.time[2] + "0"))
            // console.log('one')
        }
        else if (formData.time[2] == ":") {
            dateToTest.setHours(Number(formData.time.slice(0, 2)))
            dateToTest.setMinutes(Number(formData.time[3] + "0"))
            // console.log('two')
        }

        let nowTime = now.getTime()
        let testDateTime = dateToTest.getTime()
        let diff = testDateTime - nowTime

        function checkIfForbidden() {
            // console.log("date to test", dateToTest)
            let datesCheck = blocked_dates?.map(date => {
                let loadedStart = moment(date.start_front)
                let loadedEnd = moment(date.end_front)
                let testDate = moment(dateToTest.getTime())
                // console.log('loadedDate', loadedStart, loadedEnd, testDate)
                // loadedDate.setHours(0)
                let formDate = dateToTest
                // formDate.setHours(0)
                // formDate.setMinutes(0)
                // console.log('check', testDate.isBetween(loadedStart, loadedEnd), testDate, dateToTest, formData.time, loadedStart, loadedEnd)

                if (testDate.isBetween(loadedStart, loadedEnd)) {
                    return true
                }
            })

            if (datesCheck.includes(true)) {
                return true
            }
            else {
                return false
            }
        }

        if (diff < 10800000) {
            setTimeError(true)
        }
        else if (checkIfForbidden()) {
            alert("Na tento den není on-line rezervace možná. Kapacita rezervací je naplněna nebo nemáme v tento den otevřeno. Prosím, zavolejte nám na +420 731 592 200 nebo zkuste udělat rezervaci na jiný den.")
            setDateError(true)
        }
        else if ((diff >= 10800000) && !checkIfForbidden()) {
            // console.log('tady by se poslal email etc')


            Inertia.post('make_reservation', { ...formData }, {
                onSuccess: ()=>{setSentState("Odesláno")}
            })


            setSentState("Odesílání")
            // axios.post('/api/booking/new', {
            //     formData: {
            //         ...formData,
            //         people: formData.people == 11 ? "Více" : formData.people,
            //         timestamp: new Date()
            //     }
            // })
            //     .catch((err) => {
            //         console.log(err)
            //         setSentState("Chyba")
            //     })
            //     .then((res) => {
            //         if (res != undefined) {
            //             setSentState("Odesláno")
            //         }


            //     })

        }



    }

    function manualHandler() {
        if (formData?.people == 11) {
            setManualPersons(true)
        }
        else {
            setManualPersons(false)
        }
    }

    return (
        <div className="font-myriad">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InertiaLink href="/">
                    <div className=" cursor-pointer hidden md:block left-0 top-0 bottom-0 w-60px h-screen z-30 fixed bg-white text-black hover:text-brown-light border-l border-brown-dark border-opacity-16">
                        <div className=" whitespace-nowrap h-screen flex flex-col items-center justify-center text-16">
                            <img width={16} height={16} src="/assets/icons/cross-small.svg" />
                            <div className="transform rotate-90 h-60px flex items-center justify-center ">
                                <_>Zavřít</_>
                            </div>
                        </div>
                    </div>
                </InertiaLink>
                <div className="w-full md:h-screen min-h-screen h-auto grid grid-cols-1 md:grid-cols-2">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: (1) }}
                        transition={{ duration: 0.5 }}
                        className="relative w-full h-screen hidden md:block">
                        <Img src="/assets/img/cafebigimg.jpg" layout="fill" objectFit="cover" />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: (1) }}
                        className="order-1 md:order-2 w-full md:overflow-y-auto overflow-y-visible flex justify-center items-start lg:pt-5vw xl:items-start xl:pt-60px p-16px md:p-24px">
                        {
                            sentState == "Odesláno" ?
                                <div className="w-full md:max-w-md text-brown-light flex items-start justify-center flex-col h-full">
                                    {/* <Img className="text-white" src="/assets/img/dekujeme-full.svg" /> */}
                                    <Icon name="dekujeme" />
                                    <div className="mt-12px mb-20px">Rezervace k nám v pořádku dorazila a budeme se na vás těšit. V&nbsp;případě potřeby vás budeme telefonicky kontaktovat.</div>
                                    <InertiaLink href={route('index')}>
                                        <Button value="Dokončit" main />
                                    </InertiaLink>
                                </div>
                                :
                                <div className="w-full md:max-w-md">
                                    <div className="flex items-center justify-between mb-24px">
                                        <h1 className="text-h32 lg:text-48 font-aldo text-brown-dark"><_>Rezervace</_></h1>
                                        <InertiaLink href="/">
                                            <Icon className="md:hidden" name="cross" />
                                        </InertiaLink>

                                    </div>
                                    <form onSubmit={(event) => {
                                        event.preventDefault()
                                        fakesend()
                                    }} className="lg:grid sm:grid flex md:flex md:flex-col flex-col items-stretch sm:grid-cols-2 lg:grid-cols-2 gap-x-24px gap-y-24px">
                                        <TextField value={formData.f_name} onChange={(e) => updateForm("f_name", e.target.value)} id="standard-basic" fullWidth name="fname" label={t("Jméno")} className="TextField" placeholder={t("Vaše jméno")} InputLabelProps={{ shrink: true }} required />
                                        <TextField value={formData.l_name} onChange={(e) => updateForm("l_name", e.target.value)} id="standard-basic" fullWidth name="lname" label={t("Příjmení")} className="TextField" placeholder={t("Vaše příjmení")} InputLabelProps={{ shrink: true }} required />
                                        <div className={"col-span-2  w-full"}>
                                            {
                                                <>
                                                    <Typography id="discrete-slider-small-steps" variant="subtitle2" gutterBottom>
                                                        <_>Počet osob</_> *
                                                    </Typography>
                                                    <div className="h-2px"></div>
                                                    <Slider
                                                        onChangeCommitted={() => manualHandler()}
                                                        valueLabelDisplay={"on"}
                                                        value={(typeof (formData.people) != "number") ? 0 : formData.people}
                                                        valueLabelFormat={(formData.people == 11) ? "Více" : (formData.people ?? 0).toString()}
                                                        onChange={(e, newValue) => updateForm("people", newValue)}
                                                        aria-labelledby="discrete-slider-small-steps"
                                                        step={1}
                                                        min={1}
                                                        max={11}
                                                    />
                                                </>
                                            }
                                        </div>
                                        {manualPersons &&
                                            <div className="col-span-2 w-full bg-brown-light bg-opacity-10 text-brown-dark p-16px">
                                                <_>Náš personál vás bude neprodleně kontaktovat a vše s vámi dojednáme.</_>
                                            </div>
                                        }
                                        <TextField
                                            id="date"
                                            label={t("Datum *")}
                                            type="date"
                                            onChange={(e) => updateForm("date", e.target.value)}
                                            value={formData.date}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                        {/* <TextField
                                    id="time"
                                    label="Čas *"
                                    type="time"

                                    defaultValue="12:00"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}

                                /> */}
                                        <div className="w-full relative">
                                            {
                                                timeError &&
                                                <div className="absolute bottom-0 left-0 bg-brown-light text-white transform translate-y-full z-20 p-16px -mb-8px flex">
                                                    <div className="absolute top-0 right-0 bg-brown-light w-16px h-16px transform rotate-45 -mt-8px mr-4px"></div>
                                                    <div><_>On-line lze rezervovat pouze 3 hod a více předem. Prosím, zavolejte na:</_> <a className="underline" href="tel:+420 731 592 200">+420 731 592 200</a>.</div>
                                                    <div className="cursor-pointer" onClick={() => { setTimeError(false) }}><Icon name="cross" /></div>
                                                </div>
                                            }
                                            <Typography id="discrete-slider-small-steps" variant="subtitle2" >
                                                <_>Čas</_> *
                                            </Typography>
                                            <NativeSelect
                                                id="time"
                                                name="time"
                                                value={formData.time}
                                                onChange={(e) => updateForm("time", e.target.value)}
                                                className="w-full h-40px"
                                                required
                                            >

                                                <option value={"7:00"}>7:00</option>
                                                <option value={"7:30"}>7:30</option>
                                                <option value={"8:00"}>8:00</option>
                                                <option value={"8:30"}>8:30</option>
                                                <option value={"9:00"}>9:00</option>
                                                <option value={"9:30"}>9:30</option>
                                                <option value={"10:00"}>10:00</option>
                                                <option value={"10:30"}>10:30</option>
                                                <option value={"11:00"}>11:00</option>
                                                <option value={"11:30"}>11:30</option>
                                                <option value={"12:00"}>12:00</option>
                                                <option value={"12:30"}>12:30</option>
                                                <option value={"13:00"}>13:00</option>
                                                <option value={"13:30"}>13:30</option>
                                                <option value={"14:00"}>14:00</option>
                                                <option value={"14:30"}>14:30</option>
                                                <option value={"15:00"}>15:00</option>
                                                <option value={"15:30"}>15:30</option>
                                                <option value={"16:00"}>16:00</option>
                                                <option value={"16:30"}>16:30</option>
                                                <option value={"17:00"}>17:00</option>
                                                <option value={"17:30"}>17:30</option>
                                                <option value={"18:00"}>18:00</option>
                                                <option value={"18:30"}>18:30</option>
                                                <option value={"19:00"}>19:00</option>
                                                <option value={"19:30"}>19:30</option>
                                                <option value={"20:00"}>20:00</option>
                                                <option value={"20:30"}>20:30</option>
                                                <option value={"21:00"}>21:00</option>
                                                <option value={"21:30"}>21:30</option>
                                                <option value={"22:00"}>22:00</option>
                                                <option value={"22:30"}>22:30</option>

                                            </NativeSelect>
                                        </div>

                                        <TextField value={formData.phone} onChange={(e) => updateForm("phone", e.target.value)} id="standard-basic" type="phone" label={t("Telefon")} className="TextField" placeholder={t("Váš telefon")} InputLabelProps={{ shrink: true }} required />
                                        <TextField value={formData.mail} onChange={(e) => updateForm("mail", e.target.value)} id="standard-basic" type="mail" label="E-mail" className="TextField" placeholder={t("Váš e-mail")} InputLabelProps={{ shrink: true }} required />
                                        <div className="w-full col-span-2">
                                            <Typography id="discrete-slider-small-steps" variant="subtitle2" gutterBottom>
                                                <_>Speciální požadavky</_>
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={() => updateForm("extra_place_setting", !formData.extra_place_setting)}
                                                        checked={formData.extra_place_setting}
                                                        disableRipple
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        name="checkedF"
                                                        color="primary"
                                                    />
                                                }
                                                label={t("Přeji si prostřít (+ 50 Kč / osoba)")}
                                            />
                                        </div>
                                        <div className="w-full col-span-2">
                                            <TextField value={formData.message} onChange={(e) => updateForm("message", e.target.value)} id="standard-basic" multiline fullWidth name="message" label={t("Vaše požadavky")} className="TextField" placeholder={t("Místo pro vaše požadavky")} InputLabelProps={{ shrink: true }} />
                                        </div>
                                        <div className="flex gap-8px p-8px bg-[#F3F2F0] text-[#666666] w-full col-span-2 items-center">
                                            <Icon className={`flex-shrink-0`} name="info" />
                                            <div>Uvedete-li preferenci konkrétního místa, budeme se snažit vyhovět, bohužel však nedokážeme přesné místo zaručit.</div>
                                        </div>
                                        <div className="w-full col-span-2 lg:flex sm:flex md:block block items-center">
                                            <div className="flex-grow-1 flex-shrink-1 flex flex-col w-full">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={() => updateForm("newsletter", !formData.newsletter)}
                                                            checked={formData.newsletter}
                                                            disableRipple
                                                            disableTouchRipple
                                                            disableFocusRipple
                                                            name="checkedF"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t("Odebírat newsletter")}
                                                />
                                                <div className="flex items-center flex-wrap text-12 text-brown-text"><_>Kliknutím na rezervovat vyjadřujete svůj souhlas se </_>
                                                    <a href="/assets/cafenewone_GDPR.pdf" target="_blank" className="hover:opacity-75 underline"><_>Zpracováním osobních údajů</_></a>
                                                </div>
                                            </div>
                                            <div className="h-48px w-full md:hidden"></div>
                                            <div className="fixed bottom-0 left-0 right-0 md:relative w-full flex-grow-0 flex-shrink-0 md:w-110px lg:w-110px mt-16px">
                                                <Button value={sentState} icon={(sentState == "Odesláno") ? "tick" : (sentState == "Chyba") ? "cross" : undefined} loading={(sentState == "Odesílání") ? true : false} type="submit" main forceFullWidth />
                                            </div>
                                            
                                        </div>
                                        
                                    </form>
                                </div>
                        }
                    </motion.div>
                </div>
            </MuiPickersUtilsProvider>
        </div>

    )
}