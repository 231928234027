import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const styles = {
    width: "100vw",
    height: "calc(100vh - 80px)",
    position: "absolute"
};

const Map = () => {
    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);

    useEffect(() => {
        mapboxgl.accessToken =
            "pk.eyJ1Ijoidm9qdGFjaW5hIiwiYSI6ImNrbGpocGE5NjI5dGwyeW1najJzemJndm0ifQ.3dbrKUzSnAw_ARhWYfrUow";
        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
                center: [17.2481650, 49.5939458],
                zoom: 12
            });

            const el = document.createElement('div');
            el.className = 'marker';
            el.style.backgroundImage = 'url(/assets/img/marker.png)';
            el.style.width = "100px";
            el.style.height = "100px";
            el.style.backgroundSize = '100%';

            const marker = new mapboxgl.Marker(el)
                .setLngLat([17.2481650, 49.5939458])
                .addTo(map);



            map.on("load", () => {
                setMap(map);
                map.resize();
            });
        };

        if (!map) initializeMap({ setMap, mapContainer });
    }, [map]);

    return <div className="w-full h-full" ref={mapContainer} />;
};

export default Map;