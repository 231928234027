import Img from "app/components/Image";
import { motion } from "framer-motion";
import { useState } from "react";
import { InertiaLink } from "@inertiajs/inertia-react";
import { _ } from "app/components/Translator";


export default function GiantButton() {

    const [hovering, setHovering] = useState(false)


    return (
        <InertiaLink href="/rezervace">
            <div onPointerEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} className="cursor-pointer w-full h-450px relative bg-brown-light transition duration-500 hover:bg-opacity-90 flex justify-center overflow-hidden">

                <div className="z-10 font-aldo font-outline text-48 md:text-128 self-center justify-self-center "><_>Rezervovat</_></div>

                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center z-0">
                    <motion.div
                        animate={{
                            scale: hovering ? 1.25 : 1
                        }}
                        transition={{
                            duration: 0.5
                        }}
                        className="relative h-full w-full">
                        <Img layout="fill" objectFit="contain" src="/assets/img/feat_bg.jpg" />
                    </motion.div>
                </div>

            </div>
        </InertiaLink>
    )
}