import React, { useState, useEffect, useRef } from 'react';
import Svg from './Svg';

interface Props {
  name: string
  className?: any,
  onClick?: any
  key?: any
}

export default function Icon({ name, className="", ...props}: Props){
  
  return(
    <Svg src={"/icons/"+name} {...props} className={className} />
  )
};