import Icon from 'components/Icon'
import React, { HTMLProps, useContext, useEffect } from 'react'
import { ErrorGroupContext } from './ErrorGroup'
import { FormContext } from './Form'

interface Props extends HTMLProps<HTMLInputElement> {
    label?: string | any
}

function Checkbox(props: Props) {
    const { label, className, name = "checkbox", ...rest } = props
    const { data, setData, errors, clearErrors } = useContext(FormContext)

    const {seterrors} = useContext(ErrorGroupContext);
    useEffect(() => {
        seterrors( e => ({...e, [name]: errors[name]   }) )
    }, [errors[name]])

    return (
        <label className={`relative cursor-pointer ${className}`} >
            <div className="flex items-center">
                <input
                    className="h-0 w-0 opacity-0 absolute"
                    {...rest}
                    type="checkbox"
                    name={name}
                    // value={data[name] ?? ""}
                    checked={data[name] === true}
                    onChange={e => {setData(name, e.target.checked); clearErrors(name)}}
                />
                <div className="border flex-shrink-0 border-black rounded w-24px h-24px bg-white radio flex items-center justify-center relative" >
                    {/* <div className="bg-white w-24px h-24px hidden rounded-full" /> */}
                    <Icon className="absolute icon text-white h-18px" name="tick" />
                </div>
                <div className="ml-8px leading-6">{label}</div>
            </div>
            <div className={`h-12px text-12 text-C86B28 ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default Checkbox
