import { Inertia } from '@inertiajs/inertia'
import { get } from 'jquery'
import React, { DetailedHTMLProps, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import { usePage } from '@inertiajs/inertia-react';
import { GET } from 'app/api/api';
import route from 'ziggy-js';
import usePageProps from './hooks/usePageProps';

export interface ResponsiveImage {
     srcset: string, 
     url: string, 
     width: string, 
     height: string
}

interface Props extends Omit<DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'> {
    src: string | ResponsiveImage
    objectFit?: string,
    layout?: string,
}

function Img(props: Props) {
    const { src } = props

    const {media} = usePageProps<{media: any}>()

    const [image, setimg] = useState<ResponsiveImage>(( typeof src === "string" ? media?.[src] : src ))
    const ref = useRef<HTMLImageElement>(null);


    const updateSizes = () => {
        window.requestAnimationFrame(function () {
            if(ref.current == null)
                return

            let size = ref.current.getBoundingClientRect().width;

            if (!size)
                return;

            ref.current.sizes = Math.ceil(size / window.innerWidth * 100) + 'vw';
            
        })
    }


    useEffect(() => {
        getImage();
    }, [src])


    const getImage = async () => {
        
        let img = ( typeof src === "string" ? media?.[src] : src );

        if(typeof src === "string" && !img?.url)
        {
            setimg({srcset: "", url: src, width: "", height: "" })
            img = await GET(`/images/${btoa(src)}/${route().current()}` as string, undefined)
        }

        setimg(img);
        updateSizes();
       
    }

    const {srcset, url, width, height } = image ?? {srcset: undefined, url: undefined, width: undefined, height: undefined };


    return (  
        (srcset || url) 
        ? <img ref={ref} {...props} sizes="1px" srcSet={srcset} src={url} width={width} height={height} />
        : <img  {...props} src={src as string} />         
    )
}

export default Img

