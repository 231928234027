import { animate, transform, useMotionValue } from "framer-motion";
import { useEffect, useRef } from "react";

export default function Counter({ from, to }) {
  const nodeRef = useRef() as any;

  useEffect(() => {
    const node = nodeRef.current as any;
    node.textContent = "0"

    const controls = animate(from, to, {
      duration: 2,
      onUpdate(value) {
        node.textContent = value.toFixed(0);
      }
    });

    return () => controls.stop();
  }, []);

  return <p ref={nodeRef} />;
}