import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import Button from 'app/components/Button';
import ContentContainer from 'app/components/ContentContainer';
import Header from 'app/components/Header/Main';
import Img from 'app/components/Image';
import InstagramGrid from 'app/components/InstagramGrid';
import JanLibra from 'app/components/JanLibra';
import Layout from 'app/components/Layouts/Layout'
import MenuAndReserve from 'app/components/MenuAndReserve';
import Events from 'app/components/Pages/Index/Events';
import GiantButton from 'app/components/Pages/Index/GiantButton';
import ServicesSlideshow from 'app/components/Pages/Index/ServicesSlideshow';
import { t, _ } from 'app/components/Translator';
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import route from 'ziggy-js';


interface Props { }

function Index(props: Props) {
  const { } = props

  // console.log(props)

  const form = useForm({});
  const { data } = form;

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout title="Café New One">
      <MenuAndReserve>
        <Header />
        <div className="w-full h-screen relative overflow-hidden bg-black">
          <motion.div
            animate={{
              scale: (1 + (scrollPosition * 0.0003)), opacity: 1
            }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="w-full h-screen relative">
            <Img src="/assets/img/feat_bg.jpg" layout="fill" objectFit="cover" />
          </motion.div>
          <div className="absolute top-60px left-0 right-0 bottom-0 flex items-center justify-center text-center text-white">
            <div className="flex flex-col p-16px max-w-lg items-stretch md:items-center">
              <h1 className="text-30 md:text-48 text-white font-semibold leading-tight mb-16px"><_>Neustále překvapovat, vždy nadchnout</_></h1>
              <div className="text-18 mb-16px">
                <_>Objevte každý den něco nového v naší unikátní designové kavárně a restauraci v centru Olomouce.</_>
              </div>
              <InertiaLink href={route('menu')}>
                <Button value="Objevit menu" main />
              </InertiaLink>

            </div>
          </div>


        </div>
        <ContentContainer p={isMobile ? false : true}>
          <ServicesSlideshow />
        </ContentContainer>
        <ContentContainer>
          <div className="w-full relative h-450px md:h-auto">
            <Img src="/assets/img/cafebigimg.jpg" layout="fill" objectFit="cover" />
          </div>
        </ContentContainer>
        <ContentContainer p={true}>
          <div className="text-24 md:text-h32 font-semibold text-black pt-20px mb-16px md:mb-24px"><_>Příběh splněného snu</_></div>
          <div className="grid grid-cols-1 md:grid-cols-2 text-16 text-brown-text">
            <div className="mb-20px">
              <_>Café New One je naprosto jedinečná designová kavárna, která si potrpí především na kvalitu. Kromě kávy Tonino Lamborghini se může Caféčko pochlubit také výborným jídlem inspirovaným ikonickou Itálií.</_>
            </div>
            {/* <div className="mb-20px">
            „<_>Café New One je místem, kde vznikají sny a ambice, místem, které je samo snem jednoho člověka, který se rozhodl, že do milované Olomouce přinese kus světovosti.</_>“
            </div> */}
            {/* <div className="pb-20px">
              <InertiaLink href={route('pribeh')}>
                <Button value={t("Více o nás")} />
              </InertiaLink>
            </div> */}
          </div>
        </ContentContainer>
        <ContentContainer>
          <GiantButton />
        </ContentContainer>
        <ContentContainer p={true}>
          <Events />
        </ContentContainer>
        <ContentContainer p>
          <InstagramGrid />
        </ContentContainer>
      </MenuAndReserve>
    </Layout>
  )
}

export default Index
