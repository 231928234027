import { InertiaLink, useForm } from '@inertiajs/inertia-react'
// import Head from 'app/components/Head'

import Img from 'app/components/Image'
import { ModalsContext } from 'app/components/Layouts/Modals'
import Checkbox from 'app/fragments/Forms/Checkbox'
import PasswordField from 'app/fragments/Forms/PasswordField'

import Icon from 'components/Icon'

import Form from 'fragments/Forms/Form'

import Select from 'fragments/Forms/Select'
import Submit from 'fragments/Forms/Submit'
import TextField from 'fragments/Forms/TextField'
import React, { useContext, useEffect, useState } from 'react'
import route from 'ziggy-js'

interface Props {

}


function LoginForm() {
    return (
        <div className="flex flex-col items-start w-full">
            <PasswordField name="password" label="Heslo*" placeholder="Vaše heslo" />
            <Checkbox name='remember' label="Zapamatovat?" />
            <Submit value="Přihlásit se" className="w-full text-center justify-center" />
        </div>
    );
}

function Login(props: Props) {
    const { } = props
    const { close } = useContext(ModalsContext)

    const form = useForm({
        email: '',
    });
    const { data, post, clearErrors } = form;

    const submit = (e) => {
        e.preventDefault()
        post(route('admin.login'), {
            onSuccess: () => close()
        });
    }

    return (
        <div className="w-full h-screen bg-white z-99999 flex overflow-auto">

            <div className=" flex items-center justify-between  w-full">
                <div className="flex justify-center items-center w-full pb-80px">
                    <div className="max-w-sm w-full relative z-10 flex flex-col ">
                        <InertiaLink className="self-center mb-85px" href="/"><Img className='w-160px' src='/assets/img/logo.svg' /></InertiaLink>

                        <Form onSubmit={submit} form={form} className="flex flex-col items-start">
                            <TextField noCheck name="email" label="E-mail*" placeholder="Váš e-mail" /> 
                            <LoginForm />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center h-full border-EBE9E6 w-full flex-grow-0">
                <img src="/assets/img/feat_bg.jpg" alt="Admin panel | Cafe New One" className="w-full h-full object-cover object-center" />
            </div>

        </div>
    )
}

export default Login
