import React from 'react'
import { InertiaLink } from "@inertiajs/inertia-react";
import { Checkbox, FormControlLabel, NativeSelect, Slider, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "app/components/Button";
import Icon from "app/components/Icon";
import Img from "app/components/Image";
import { motion } from "framer-motion";
import { useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import axios from "axios";
import { _, t } from "app/components/Translator";
import route from "ziggy-js";
import moment from "moment";
import { Inertia } from "@inertiajs/inertia";
import InvertedButton from 'app/components/Buttons/InvertedButton';

interface Props {
    reservation: {
        id: number,
        first_name: string,
        last_name: string,
        amount_of_people: number,
        date: Date,
        number: number,
        email: string,
        set_table: boolean,
        requirements: string
    }
}

function Cancel_reservation(props: Props) {
    const { reservation } = props

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InertiaLink href="/">
                    <div className=" cursor-pointer hidden md:block left-0 top-0 bottom-0 w-60px h-screen z-30 fixed bg-white text-black hover:text-brown-light border-l border-brown-dark border-opacity-16">
                        <div className=" whitespace-nowrap h-screen flex flex-col items-center justify-center text-16">
                            <img width={16} height={16} src="/assets/icons/cross-small.svg" />
                            <div className="transform rotate-90 h-60px flex items-center justify-center ">
                                <_>Zavřít</_>
                            </div>
                        </div>
                    </div>
                </InertiaLink>
                <div className="w-full md:h-screen min-h-screen h-auto grid grid-cols-1 md:grid-cols-2">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: (1) }}
                        transition={{ duration: 0.5 }}
                        className="relative w-full h-screen hidden md:block">
                        <Img src="/assets/img/cafebigimg.jpg" layout="fill" objectFit="cover" />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: (1) }}
                        className="order-1 md:order-2 w-full md:overflow-y-auto overflow-y-visible flex justify-center items-start lg:pt-5vw xl:items-start xl:pt-60px p-16px md:p-24px">
                        <div className="w-full md:max-w-md text-brown-light flex items-start justify-center flex-col h-full">
                            {/* <Img className="text-white" src="/assets/img/dekujeme-full.svg" /> */}
                            {/* <Icon name="dekujeme" /> */}
                            <div className="mt-12px mb-20px">Přejete si zrušit rezervaci?</div>
                            <div className='flex gap-16px'>
                                <InertiaLink as="button" method="post" href={route('cancel_reservation', { reservation: reservation.id })}>
                                    <Button value="Ano, přeji si zrušit rezervaci" main />
                                </InertiaLink>

                                <InertiaLink href={route('index')}>
                                    <Button value="Zpátky na úvod" />
                                </InertiaLink>
                            </div>

                        </div>
                    </motion.div>
                </div>
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default Cancel_reservation
