import { Inertia, Method } from '@inertiajs/inertia';
import { InertiaFormProps, useForm, usePage } from '@inertiajs/inertia-react';
import { limit } from 'app/utils/utils';
import React, { useEffect, useRef, useState } from 'react'
import { MetaType } from '../Types/MetaType';
import usePageProps from './usePageProps';

interface LazyButton {

    only: Array<string>,
    preserveScroll: boolean,
    preserveState: boolean,
    method: "POST" | "GET",
    data: { page?: number, paginate?: number, sort?: Array<{ name: string, order: 'ASC' | 'DESC' }> },
    as: "button",
    className: string | undefined

}

export default function useLazyLoad<T>(key: string, _with: Array<string> = [], defaultValues: Record<string, any> = {}, fKeys = {sort: 'sort', paginate: 'paginate', page: 'page'}): [Array<T>, LazyButton, MetaType, InertiaFormProps<any>, React.Dispatch<React.SetStateAction<T[]>>, React.Dispatch<any> ] {

    const initialRender = useRef(true);
 
    const { [key]: item } = usePageProps<{ [key: string]: { data: Array<T>, meta: MetaType } }>();

    // let [transform, setTransform] = useState<Function>( ((data) => data) );
    const [transform, setTransform] = useState(() => (data) => data);

    let { meta, data } = item;

    const form = useForm({
        [fKeys.paginate]: meta?.per_page ?? 10,
        ...defaultValues
    });
    const { data: filters } = form;

    meta.next = limit(meta.total - meta.to, 0, meta.per_page);

    const [items, setitems] = useState([] as Array<T>);




    useEffect(() => {
        if (data) {
            setitems(itms => [...(meta.current_page > 1 ? itms : []), ...data])
        }
    }, [data, meta])

    useEffect(() => {

        if(initialRender.current === true)
        {
            initialRender.current = false
            return;
        }

        Inertia.visit("?", {
            only: [key, ..._with],
            preserveScroll: true,
            preserveState: true,
            method: Method.POST,
            data: transform({
                ...filters,
                [fKeys.page]: 1,
                [fKeys.paginate]: filters["paginate"],
                [fKeys.sort]:  (filters['order_by'] ) ?  [
                    { name: filters['order_by'], order: filters['order'] ?? 'asc' }
                ] : null
            }),
        })

    }, [filters])

    return [
        items,
        {
            only: [key, ..._with],
            preserveScroll: true,
            preserveState: true,
            method: "POST",
            data: transform({
                ...filters,
                [fKeys.page]: meta?.current_page + 1, 
                [fKeys.paginate]: filters["paginate"],
                [fKeys.sort]: (filters['order_by']) ?  [
                    { name: filters['order_by'], order: filters['order'] ?? 'asc' }
                ] : undefined
            }),
            as: "button",
            className: meta?.current_page == meta?.last_page ? "hidden pointer-events-none" : undefined
        },
        meta,
        form,
        setitems,
        setTransform
    ]
}
