import Icon from 'app/components/Icon'
import Img from 'app/components/Img';
import { ModalsContext } from 'app/components/Layouts/Modals';
import { ScreenContext } from 'app/components/ScreenContext';
import { limit } from 'app/utils/utils';
import React, { useContext, useState } from 'react'
import Video from '../components/Video';

interface Props {
    imgs: Array<{src: string, alt:string}>
    index?: number
}

function GaleryOverlay(props: Props) {
    const {imgs, index: i = 0} = props

    let [index, setIndex] = useState(i);
    let {isMobile} = useContext(ScreenContext);
    let [shouldPlay, setShouldPlay] = useState(true);
    const {close} = useContext(ModalsContext)

    let image = imgs?.[index];

    return (
        <div className="bg-black bg-opacity-80 backdrop-blur-sm fixed top-0 left-0 w-full h-screen items-center justify-center flex z-99999 px-50px mob:px-24px mob:pb-0">
            <div className="max-w-screen-1441min max-h-900px grid galery-grid text-white w-full h-full">
                <div className="text-center pb-120px mob:pb-30px pt-24px relative">
                    <div>{index + 1}/{imgs?.length}</div>
                    <Icon className="absolute right-70px mob:right-0 top-24px cursor-pointer" name="cross-small"  onClick={close}/>
                </div>
                <div className="flex justify-between items-center h-full w-full overflow-hidden">
                    <div className="w-max flex-grow-0 flex-shrink-0">
                        <button className="focus:outline-none" onClick={(e)=>{setIndex(i => limit(i - 1, 0, imgs.length - 1)); e.preventDefault(); return false;}}>
                            <Icon className={`transform rotate-180 cursor-pointer ${index == 0 && "pointer-events-none opacity-8"}`} name="arrow-right" />
                        </button>
                    </div>
                    <div className="px-40px flex-shrink flex-grow h-full flex justify-center items-center">        
                        {/* <Img className="object-contain object-center w-full h-full" src="/assets/img/jinan8.png" alt="Jinan" /> */}
                        {
                            image?.src.includes('.mp4') ?
                            <Video muted autoPlay onMouseOver={(e) => { e.target.play(); setShouldPlay(true); }} onPause={(e) => { setShouldPlay(false) }} shouldplay={shouldPlay} src={image.src} thumbnail={image.alt} />
                            :
                            <Img className="object-contain object-center w-full h-full" src={image?.src} alt=""/>
                            
                        }
                    </div>
                    <div className="w-max flex-grow-0 flex-shrink-0">
                        <button className="focus:outline-none" onClick={(e)=>{setIndex(i => limit(i + 1, 0, imgs.length - 1)); e.preventDefault(); return false;}}>
                            <Icon className={`cursor-pointer ${(index == (imgs?.length -1)) && "pointer-events-none opacity-8"}`} name="arrow-right"  />
                        </button>
                    </div>
                </div>
                <div className="pb-120px mob:pb-30px pt-60px text-center">{image?.alt}</div>
            </div>
        </div>
    )
}

export default GaleryOverlay
