
import { createPortal } from 'react-dom'
import { useState, useEffect, useContext, useRef } from 'react'

export default function Portal({ children }) {


    if (typeof window !== 'undefined') {
        const portalView = document.getElementById("portal-view")



        if (portalView) {
            return createPortal(
                <>
                    {children}
                </>




                , portalView
            )
        }
        else {
            return null as any
        }

    }
    else {
        return null as any
    }


}

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: null as any,
        height: null as any,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            try {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            } catch (error) { }
        }
        // Add event listener
        try {
            window.addEventListener("resize", handleResize);
        } catch (error) { }
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => {
            try {
                window.removeEventListener("resize", handleResize)
            } catch (error) { }
        };
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}