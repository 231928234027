import { Inertia } from "@inertiajs/inertia";
import { InertiaLink } from "@inertiajs/inertia-react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import route from "ziggy-js";
import { t } from "./Translator";

export default function MenuAndReserve({ children, onlyReserve, dark }: { children: any, onlyReserve?: boolean, dark?: boolean }) {

    const [overlay, openOverlay] = useState(false)
    const [mobileOverlay, openMobileOverlay] = useState(false)

    const [reserve, openReserve] = useState(false)

    useEffect(() => {
        if(overlay || mobileOverlay) {
            setTimeout(() => {
                Inertia.visit(route('menu'))
            }, 250)
        }
    }, [overlay, mobileOverlay])

    useEffect(() => {
        if(reserve) {
            setTimeout(() => {
                Inertia.visit(route("rezervace"))
            }, 250)
        }
    }, [reserve])

    return (
        <div className={`w-full ${dark ? "bg-brown-dark" : "bg-white"} relative min-h-screen z-30`}>
            {!onlyReserve &&
                <>
                    <motion.div 
                    onClick={() => openMobileOverlay(true)}
                    animate={{
                        height: mobileOverlay ? "100%" : 40
                    }}
                    transition={{ease: "easeInOut"}}
                    initial={false}
                    className={`z-20 md:hidden w-full  ${dark ? "bg-brown-dark border-brown-inverted" : "bg-white border-brown-dark"}  flex fixed bottom-0 items-center justify-center text-14 underline border-t  border-opacity-16 cursor-pointer`}>{mobileOverlay ? "" : "Zobrazit denní menu"}</motion.div>
                    <div onClick={() => openOverlay(true)}>
                        <motion.div
                            animate={{
                                width: overlay ? "100%" : 60
                            }}
                            initial={false}
                            transition={{ease: "easeInOut"}}
                            className={`z-30 cursor-pointer hidden md:block left-0 top-0 bottom-0 h-screen fixed ${dark ? "bg-brown-dark " : "bg-white "} hover:text-brown-light border-r border-brown-dark border-opacity-16`}>
                            <div className="w-full whitespace-nowrap h-screen flex items-center justify-center text-16"  style={{writingMode: "vertical-rl"}}>{overlay ? "" : t("Jídelní lístek")}</div>
                        </motion.div>
                    </div>
                </>
            }

<>
              
                    <div onClick={() => openReserve(true)}>
                        <motion.div
                            animate={{
                                width: reserve ? "100%" : 60
                            }}
                            initial={false}
                            transition={{ease: "easeInOut"}}
                            className={`z-30 cursor-pointer hidden md:block right-0 top-0 bottom-0 h-screen fixed ${(dark && !reserve) ? "bg-brown-dark border-brown-inverted text-white " : "bg-white border-brown-dark"} hover:text-brown-light border-l border-opacity-16`}>
                            <div className="whitespace-nowrap w-full h-screen flex items-center justify-center text-16" style={{writingMode: "vertical-rl"}}>{reserve ? "" : t("Rezervace")}</div>
                        </motion.div>
                    </div>
                </>
            {children}
        </div>
    )
}