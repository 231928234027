import Img from "./Image";
import { _ } from "./Translator";


export default function Kosvanec() {

    return (
        <div className="w-full h-250px md:h-450px lg:h-815px grid grid-cols-1 lg:grid-cols-3 relative" name="slovo-sefkuchare">
            <div className="hidden lg:block"></div>
            <div className="hidden lg:block 2xl:hidden"></div>
            <div className=" h-full flex flex-col justify-end lg:justify-center items-start z-10 text-white p-16px pr-60px 2xl:pl-200px 2xl:col-span-2 2xl:pr-200px">
                <h2 className="text-h32 md:text-64 font-outline font-aldo hidden">Tomáš Kosvanec</h2>
                <img src="/assets/img/tomaskosvanec.svg" className="mb-20px w-200px md:w-300px" />
                <p className="text-16"><_>F&B Manager</_></p>
                <p className="hidden lg:block py-16px text-16">
                <_>Manažerské zkušenosti v průběhu let získal v několika prvotřídních hotelových restauracích v Dublinu a Londýně, jeho srdce si však nakonec získala Olomouc, a když se naskytnula možnost stát se součástí příběhu Café New One, prý neváhal ani minutu. Z pozice F&B Managera dohlíží na to, aby se u nás všechno fungovalo jako na drátkách.</_>
                </p>
                <p className="hidden lg:block py-16px text-16">
                    +420 734 391 471<br />
                    manager@cafenewone.cz
                </p>
                <div className="hidden lg:block border-l border-white pl-24px text-16 italic">
                    „<_>Hlavní kouzlo všeho, co děláme, tkví v osobním přístupu. Chci, aby každý, kdo do Café New One zavítá, cítil, že přišel na místo, kde ho lidé dobře znají.</_>“
                </div>
            </div>
            <Img src="/assets/img/signatures/kosvanec.svg" className="absolute z-10  -bottom-20px lg:bottom-50px right-0 lg:right-0 lg:left-0 lg:mx-auto w-1/2 md:w-300px" />
            <Img src="/assets/img/kosvanec.jpeg" className="absolute transform z-0 top-0 left-0 bottom-0 right-0 object-top" layout="fill" objectFit="cover" />
        </div>
    )
}