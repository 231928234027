import Button from "app/components/Button";
import ContentContainer from "app/components/ContentContainer";
import Header from "app/components/Header/Main";
import OpeningWidget from "app/components/Header/OpeningWidget";
import Img from "app/components/Image";
import Layout from "app/components/Layouts/Layout";
import MenuAndReserve from "app/components/MenuAndReserve";
import { motion } from "framer-motion";
import { stringify } from "postcss";
import { Children } from "react";
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Map from 'components/Map'
import JanLibra from "app/components/JanLibra";
import ServicesSlideshow from "app/components/Pages/Index/ServicesSlideshow";
import Events from "app/components/Pages/Index/Events";
import InstagramGrid from "app/components/InstagramGrid";
import { _ } from "app/components/Translator";




export default function Pribeh() {



    return (
        <Layout onlyReserve>
            <MenuAndReserve onlyReserve>
                <Header wide relative />
                <ContentContainer onlyReserve p>
                    <div className="py-8px md:py-60px">
                        <h1 className="text-24 md:text-h32 font-semibold text-black mb-24px"><_>Příběh splněného snu</_></h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 text-brown-text gap-x-48px lg:gap-x-80px gap-y-24px">
                            <div>
                                <_>Café New One je naprosto jedinečná designová kavárna, která si potrpí především na kvalitu. Kromě kávy Tonino Lamborghini se může Caféčko pochlubit také výborným jídlem inspirovaným ikonickou Itálií.</_>
                            </div>
                            <div className="block border-l border-brown-light pl-24px text-16 italic text-brown-text">
                                „<_>Café New One je místem, kde vznikají sny a ambice, místem, které je samo snem jednoho člověka, který se rozhodl, že do milované Olomouce přinese kus světovosti.</_>“
                            </div>
                        </div>
                    </div>
                </ContentContainer>
                <ContentContainer onlyReserve>
                    <div className="relative w-full h-200px md:h-450px lg:h-550px">
                        <Img src="/assets/img/cafebigimg.jpg" layout="fill" objectFit="cover" />
                    </div>
                </ContentContainer>
                <ContentContainer onlyReserve p>
                    <ServicesSlideshow />
                </ContentContainer>
                <ContentContainer onlyReserve p>
                    <Events />
                </ContentContainer>
                <ContentContainer onlyReserve>
                    <JanLibra />
                </ContentContainer>
                <ContentContainer p onlyReserve>
                    <InstagramGrid />
                </ContentContainer>
            </MenuAndReserve>
        </Layout>

    )
}