import { Inertia } from "@inertiajs/inertia"
import { InertiaLink } from "@inertiajs/inertia-react"
import { motion, AnimatePresence } from "framer-motion"
import { useEffect, useState } from "react"
import route from "ziggy-js"
import usePageProps from "../hooks/usePageProps"
import Icon from "../Icon"
import Img from "../Image"
import Portal from "../Portal"
import { _ } from "../Translator"

export default function MenuButton({ noMobileShift, dark }: { noMobileShift?: boolean, dark?: boolean }) {

    const [hovered, setHovered] = useState(false)
    const [opened, setOpened] = useState(false)
    const [first, setFirst] = useState(true);

    const { locale } = usePageProps() as any

    function currPageSlug() {
        let url = window.location.href
        return (window.location.href)
    }

    function getLastPart(url) {
        var parts = url.split("/");
        return (url.lastIndexOf('/') !== url.length - 1 ? parts[parts.length - 1] : parts[parts.length - 2]);
    }

    Inertia.on('start', (event) => {
        // console.log("start")
        document.body.classList.remove('lock-scroll')
        document?.getElementById('layout')?.classList.remove('lock-scroll')
    })

    useEffect(() => {
        if (!first) {
            document.body.classList.toggle('lock-scroll')
            document?.getElementById('layout')?.classList.toggle('lock-scroll')
        }
        setFirst(false)
    }, [opened]);

    return (
        <div>
            <div className="flex cursor-pointer" onClick={() => setOpened(!opened)} onPointerEnter={() => setHovered(true)} onPointerLeave={() => setHovered(false)}>
                <div className="font-semibold text-14">Menu</div>
                <div className={"w-24px h-20px flex justify-around items-end flex-col z-70 transition transform duration-200 " + ((opened && !noMobileShift) ? " -translate-y-40px md:translate-y-0  translate-x-0 md:translate-x-60px " : (opened && noMobileShift) && " md:translate-y-0 md:translate-x-60px ")}>
                    <motion.div
                        animate={{ width: (hovered || opened) ? 16 : 6, rotate: opened ? 45 : 0, y: opened ? 7 : 0, backgroundColor: (opened || dark) ? "rgb(255,255,255)" : "rgb(69, 64, 60)" }}
                        initial={{ width: 0 }}
                        className={`w-6px h-2px rounded-full ${dark ? "bg-white" : "bg-brown-dark"} `}></motion.div>
                    <motion.div
                        animate={{ width: (hovered || opened) ? 16 : 16, opacity: opened ? 0 : 1 }}
                        initial={{ width: 0 }}
                        className={`w-16px h-2px rounded-full ${dark ? "bg-white" : "bg-brown-dark"} `}></motion.div>
                    <motion.div
                        animate={{ width: (hovered || opened) ? 16 : 10, rotate: opened ? -45 : 0, y: opened ? -7 : 0, backgroundColor: (opened || dark) ? "rgb(255,255,255)" : "rgb(69, 64, 60)" }}
                        initial={{ width: 0 }}
                        className={`w-10px h-2px rounded-full ${dark ? "bg-white" : "bg-brown-dark"} `}></motion.div>
                </div>
            </div>
            <AnimatePresence>
                {opened &&
                    <Portal>
                        <motion.div
                            exit={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            initial={{ opacity: 0 }}
                            className="fixed top-0 right-0 left-0 bottom-0 bg-black z-60">
                            <div className="absolute md:hidden left-16px top-16px z-40 w-70px h-24px">
                                <Img src="/assets/img/logo_w.svg" layout="fill" objectFit="contain" />
                            </div>
                            <div onClick={() => { setOpened(false) }} className="absolute cursor-pointer text-white right-16px top-16px md:top-24px md:right-24px z-70 w-24px h-24px">
                                <img src="/assets/icons/cross-small-w.svg" />
                            </div>

                            <div className="fixed -top-60px md:top-0 right-0 left-0 bottom-0 flex flex-col items-center justify-around z-50 text-white ">
                                <div className="hidden md:block">
                                    <Img src="/assets/img/logo_w.svg" />
                                </div>
                                <div className="font-aldo text-48 md:text-64 px-60px md:px-160px lg:px-200px">
                                    <div className="flex flex-col md:flex-row gap-x-30px md:gap-x-100px gap-y-0 flex-wrap justify-center items-center">
                                        <InertiaLink href={route('index')}><motion.div whileHover={{ scale: 1.1 }} className={"lowercase " + ((getLastPart(currPageSlug()) == "cs" || getLastPart(currPageSlug()) == ("en")) ? "" : " font-outline hover-outline")}><_>Úvod</_></motion.div></InertiaLink>
                                        <InertiaLink href={route('menu')}><motion.div whileHover={{ scale: 1.1 }} className={"lowercase " + ((getLastPart(currPageSlug()) == "menu") ? "" : " font-outline hover-outline")}><_>Menu</_></motion.div></InertiaLink>
                                        <InertiaLink href={route('eventy')}><motion.div whileHover={{ scale: 1.1 }} className={"lowercase " + ((getLastPart(currPageSlug()) == ("eventy") || getLastPart(currPageSlug()) == ("events")) ? "" : " font-outline hover-outline")}><_>Eventy</_></motion.div></InertiaLink>
                                        <InertiaLink href={route('kontakt')}><motion.div whileHover={{ scale: 1.1 }} className={"lowercase " + ((getLastPart(currPageSlug()) == "kontakt") || getLastPart(currPageSlug()) == ("contact") ? "" : " font-outline hover-outline")}><_>Kontakt</_></motion.div></InertiaLink>
                                        <InertiaLink href={route('rezervace')}><motion.div whileHover={{ scale: 1.1 }} className={"lowercase " + ((getLastPart(currPageSlug()) == "rezervace") || getLastPart(currPageSlug()) == ("booking") ? "" : " font-outline hover-outline")}><_>Rezervace</_></motion.div></InertiaLink>

                                        <a target="_blank" href={'https://pro.smartvoucher.cz/podnik/cafe-new-one?source=widget'}><motion.div whileHover={{ scale: 1.1 }} className={"lowercase " + ((getLastPart(currPageSlug()) == "rezervace") || getLastPart(currPageSlug()) == ("booking") ? "" : " font-outline hover-outline")}><_>Vouchery</_></motion.div></a>
                                    </div>


                                </div>
                                <div className="absolute md:relative bottom-20px flex flex-col md:flex-row gap-x-40px gap-y-8px items-center">
                                    <div className="flex text-14 font-semibold items-center gap-x-12px">
                                        <Icon name="smartphone" className="text-transparent" />
                                        <a href="tel:+420731592200">+420 731 592 200</a>
                                    </div>
                                    <div className="flex  text-14 font-semibold items-center gap-x-12px">
                                        <Icon name="mail" className="text-transparent" />
                                        <a href="mailto:info@cafenewone.cz">info@cafenewone.cz</a>
                                    </div>


                                    {/* Přepínání jazyka */}

                                    <div className="block w-40px h-1px bg-white"></div>
                                    <div className="flex text-14 font-semibold gap-x-8px">
                                        <a href={"/cs"} className={(locale != "cs") ? "opacity-50" : ""}>CZ</a>
                                        <a href={"/en"} className={(locale != "en") ? "opacity-50" : ""}>EN</a>
                                    </div>
                                </div>
                            </div>


                            <motion.div className="z-30 relative w-full h-full"
                                animate={{ opacity: 1 }}
                                initial={{ opacity: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ ease: 'easeInOut', delay: 0.2 }}
                            >
                                <Img src="/assets/img/menu-bg.jpg" layout="fill" objectFit="cover" />
                            </motion.div>
                        </motion.div>
                    </Portal>
                }
            </AnimatePresence>

        </div>
    )
}